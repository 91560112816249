import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import "../../../assets/css/header.css";
import { Container, Row, Col, Image} from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from '../../../assets/img/agent_logo.webp';
import Logomobile from '../../../assets/img/agent_mobile_logo.webp';

// import * as Icon from 'react-bootstrap-icons';
import Mainmenu from '../../../componets/Applications/Header/Mainmenu';

const Header = (props) => {
  // console.log("props header", props.state.appState.showings);
  // console.log("page", props.page);
  const history = useHistory();

  return (
    <header>      

      <div className="header_desktop">
          <Container>
              <Row className="py-3" bg="info">
                  <Col md={5}>
                  <Link to="/"><Image src={Logo} className="logodesktop" alt="Logo" /></Link>
                  </Col>
                  <Col md={5} className="d-flex justify-content-end align-items-center">
                      <div>
                          <div className="bg-dark d-flex py-2">
                              <a href="tel:4165281407" className="d-flex justify-content-center align-items-center ps-4 pe-2">
                                  <div className="bg-header_mobile_icon"></div>
                                  <p className="text-white mb-0 ms-2">{process.env.REACT_APP_PHONE_NUMBER}</p>
                              </a>
                              <a href="mailto:0605bala@gmail.com" className="d-flex align-items-center ps-3 pe-4">
                                  <div className="bg-header_mail_icon"></div>
                                  <p className="text-white mb-0 ms-2">{process.env.REACT_APP_EMAIL}</p>
                              </a>
                          </div>
                      </div>
                  </Col>
                  <Col md={2} className="d-flex justify-content-end align-items-center">
                      <a href={process.env.REACT_APP_FB_LINK} target="_blank" rel="noopener noreferrer">
                          <div className="bg-header_socialmedia_facebook me-3"></div>
                      </a>
                      <a href={process.env.REACT_APP_TWITTER_LINK} target="_blank" rel="noopener noreferrer">
                          <div className="bg-header_socialmedia_twitter me-3"></div>
                      </a>
                      <a href={process.env.REACT_APP_YOUTUBE_LINK} target="_blank" rel="noopener noreferrer">
                          <div className="bg-header_socialmedia_youtube me-3"></div>
                      </a>
                      <a href={process.env.REACT_APP_INSTA_LINK} target="_blank" rel="noopener noreferrer">
                          <div className="bg-header_socialmedia_linkedin"></div>
                      </a>
                  </Col>
              </Row>
          </Container>
          <hr className="mb-0 mt-0"/>
          <Container>
              <Mainmenu/>
          </Container>
      </div>

      <Container className="header_mobile">
          <Row className="pt-3 header_mobile" bg="info">
              <div className="d-flex justify-content-center align-items-center fixed_bottom vm_bg_secondary position-fixed py-2">
                      <div className="bg-header_mobile_icon"></div>
                      <p className="text-white mb-0 ms-2">{process.env.REACT_APP_PHONE_NUMBER}</p>
              </div>
              <Col md={5} className="d-flex justify-content-between align-items-center">
                  <div>
                      <Image src={Logomobile} className="logomobile" alt="Logo" />
                  </div>
                  <Mainmenu/>
              </Col>
              <Col md={5} className="d-flex justify-content-center align-items-center bg-dark py-2 mt-2">
                  <div className="bg-header_mail_icon"></div>
                  <p className="text-white mb-0 ms-2">{process.env.REACT_APP_EMAIL}</p>
              </Col>
          </Row>
      </Container>
  </header>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps, null)(Header);
