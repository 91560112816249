import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col,Image } from "react-bootstrap";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
import innerBanner from "./../assets/img/agent_main_banner3.webp"
import img1 from "./../assets/img/sell_home.webp"
import { Link } from 'react-router-dom'

const TITLE = process.env.REACT_APP_SITE_NAME+' - Sell'  
const Sell = () => {
    window.scrollTo(0,0);
    return (
        <>
        <Helmet>
            <title>{ TITLE }</title>
        </Helmet>
        <HeaderComponent />
            <div>                
                <div>
                    <Image src={innerBanner} className="w-100"/>
                </div>
                <Container className="py-5 mb-4 sell">
                        <h1 className="vm_fm_secondary text-center text-uppercase pb-3 pb-md-0 vm_font_bold">Sell</h1>
                        <p className="pt-2 text-center">Even people who have been happy in their homes for a long time have considered selling their houses the past few months. With stories of above-asking offers and bidding wars, it’s hard to ignore the potential to cash out at the top of the market.</p>

                    <Row className="pt-4">
                        <Col className="pb-4 pb-md-0" md={8}>
                            <Image className="w-100" src={img1}/>
                        </Col>
                        <Col md={4}>
                            <h3 className="highlight text-dark text-center text-md-start text-uppercase vm_fm_secondary pt-md-2 pb-3">
                            Top Priorities Homeowners Should Focus On If They Want to Get Their Yard Ready For Fall and Enhance Their Resale Value
                            </h3>
                            <div className="d-flex justify-content-center justify-content-md-start">
                                <Link to="/contact-us" className="border px-3 py-2 h2 vm_fm_secondary text-uppercase vm_bg_secondary text-white">Contact us</Link>
                            </div>
                        </Col>
                    </Row>

                    <p className="pt-5 text-center text-md-start">
                    While not every buyer is going to pay this much attention, it’s wise to make sure your landscaping slopes away from the foundation of the house. One thing inspectors look for is whether there are cracks in the foundation or ways for water to seep into the basement or lowest floor of the home.
                    </p>
                    <p className="text-center text-md-start">
                    Water that flows during storms down toward your foundation can pool there. In the winter, in particular, water seeping into micro-fissures in the foundation can freeze and expand, making cracks worse. The easiest way to prevent this over time is to grade the yard so as to get water flowing out and away, not hanging out by the foundation at all. While this may be a bigger job than mulching or raking, it can be worth it to really inspire confidence in careful homebuyers. 
                    </p>

                </Container>
            </div>
        )
        <FooterComponent />
    </>
  );
};

export default Sell;