import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "./../../common/ajax";
import { useParams, useHistory } from "react-router-dom";
import { Col,Tabs, Tab,Row } from "react-bootstrap";
import { compose, withProps, withStateHandlers } from "recompose";
import Square from "./../../assets/img/square.webp";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
} from "react-google-maps";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import Swal from "sweetalert2";
import { cloneDeep } from "lodash";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchModel from "../../Models/SearchModel";
import { saveSearchResults } from "../../store/actions/Search/SearchActions";
import { setShowings } from "../../store/actions/App/AppActions";

import SettingModel from "../admin/models/SettingModel";

import HeaderComponent from "../../componets/Applications/Header/Header";
import FooterComponent from "../../componets/Applications/Footer/Footer";

import Card from "../../componets/Card";
import CardInfobox from "../../componets/CardInfobox";
import Switch from "../../componets/Switch";
import '../../assets/css/featured_properties.css';

//import Geocode from "react-geocode";

// Geocode.setApiKey(`${process.env.REACT_APP_MAP_API_KEY}`);
// Geocode.enableDebug();

const EstateHome = (props) => {
  let history = useHistory();

  const { locationName } = useParams();
  // const [area, setArea] = useState(locationName);
  const [count, setCount] = useState(0);
  const [tempSearchData, setTempSearchData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [requestData, setRequestData] = useState({ next_page_url: null });
  const [search, setSearch] = useState({
    propertyType: "RESEDENTIAL",
    buildingType: "any",
    transactionType: "any",
    keyword: "",
    minPrice: 0,
    maxPrice: 0,
    bed: "any",
    bath: "any",    
    parking: "any",
  });
  // const [priceSort, setPriceSort] = useState(null);

  const [mapView, setMapView] = useState(true);

  // google map
  const [mapPositionLat, setMapPositionLat] = useState(43.653226);
  const [mapPositionLng, setMapPositionLng] = useState(-79.383184);

  useEffect(() => {
    getGeoCodeByLocation();
    getPropertyByArea();
    // setArea(locationName);

    console.log("Props => ", props.state);

    // geo geo code by search location
    // const getSelectedGeoCode = getPositionByAdress(locationName);
    // getSelectedGeoCode ? (
    //     setMapPosition({lat: `${getSelectedGeoCode.lat}`, lng: `${getSelectedGeoCode.lng}`})
    // ): (
    //     setMapPosition({lat: `${mapPosition.lat}`, lng: `${mapPosition.lng}`})
    // )
  }, [locationName]);

  /**
   * get search data
   */
  const getPropertyByArea = async () => {
    setSearching(true);

    // setSearch({ ...search, keyword: locationName });

    let res = await SearchModel.search({ ...search, keyword: locationName });
    console.log(`res => `, res);
    props.saveSearchResults(res);

    // this is for lazy loading
    setRequestData(res.results);

    if (res.results.data.length > 0) {
      setSearchData(res.results.data);
      setTempSearchData(res.results.data);
    } else {
      setSearchData([]);
      setTempSearchData([]);
    }

    setSearching(false);
  };

  const fetchMore = async () => {
    try {
      setSearching(true);
      console.log("Fetching more");

      if (requestData.next_page_url) {
        console.log("fetchMore ", requestData);

        let _temp = cloneDeep(searchData);
        let _search = { ...search };
        console.log("_search", _search);

        if (_search.keyword === "") {
          console.log("????", locationName);
          _search = { ..._search, keyword: locationName };
        }

        const res = await axios.post(requestData.next_page_url, _search);

        console.log("res", res);

        _temp.push(...res.data.results.data);

        console.log("aaa", _temp);

        setSearchData([..._temp]);
        setTempSearchData([..._temp]);

        // this is for lazy loading
        setRequestData(res.data.results);

        // setSearching(false);

        // if (priceSort != null) {
        //   handlePriceSort(priceSort);
        // }

        setTimeout(function () {
          setSearching(false);
        }, 1000);
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  /**
   * get geocode by search location
   */
  const getGeoCodeByLocation = () => {
    setSearching(true);
    axios
      .get(`getGeocode/${locationName}`, {})
      .then((res) => {
        const data = res.data.res;
        console.log(`data => `, data);
        setMapPositionLat(parseFloat(data.Mun_lat));
        setMapPositionLng(parseFloat(data.Mun_lng));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [hoveredProperty, setHoveredProperty] = useState(false);

  const goTo = (payload) => {
    // console.log("payload", payload);
    // return;
    history.push(
      `/estate/details/${payload.property_id}/${payload.property_type}`
    );
  };

  
  var searchText='';
  if(search.propertyType=="RESEDENTIAL"){
  if(search.keyword && search.keyword != ''){
    searchText+=search.keyword;
  } if(search.buildingType && search.buildingType != '' && search.buildingType != 'any' && search.buildingType != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+=search.buildingType;
  } if(search.transactionType && search.transactionType != '' && search.transactionType != 'any' && search.transactionType != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+=search.transactionType;
  }if(search.minPrice && search.minPrice != '' && search.minPrice != 'any' && search.minPrice != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+='Greater than : $'+search.minPrice;
  }if(search.maxPrice && search.maxPrice != '' && search.maxPrice != 'any' && search.maxPrice != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+='Less than : $'+search.maxPrice;
  }if(search.bed && search.bed != '' && search.bed != 'any' && search.bed != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+=search.bed+' Bed(s)';
  }if(search.bath && search.bath != '' && search.bath != 'any' && search.bath != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+=search.bath+' Bath(s)';
  }if(search.parking && search.parking != '' && search.parking != 'any' && search.parking != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+=search.parking+' Parking(s)';
  }
  if(searchText != ''){searchText='for '+searchText;}
  }

  const MyMapComponent = React.memo(
    compose(
      withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `900px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
        center: { lat: mapPositionLat, lng: mapPositionLng },
      }),
      withStateHandlers(
        () => ({
          isOpen: false,
          index: null,
          goTo: goTo,
        }),
        {
          onToggleOpen:
            ({ isOpen, index }) =>
            ({ index }) => ({
              isOpen: !isOpen,
              index,
            }),
          navigateToPage: (mapState) => (mapProps) => {
            console.log("asss");
            mapState.goTo(mapProps);
          },
        }
      ),
      withScriptjs,
      withGoogleMap
    )((props) => (
      <GoogleMap defaultZoom={10} defaultCenter={props.center}>
        {/*<InfoBox
        defaultPosition={
          new google.maps.LatLng(props.center.lat, props.center.lng)
        }
        options={{ closeBoxURL: ``, enableEventPropagation: true }}
      >
        <div
          style={{ backgroundColor: `yellow`, opacity: 0.75, padding: `12px` }}
        >
          <div style={{ fontSize: `16px`, fontColor: `#08233B` }}>
            Hello, Taipei!
          </div>
        </div>
      </InfoBox>*/}

        {searchData.length > 0 ? (
          searchData.map((item, index) => {
            {
              /*console.log("item => ", item);*/
            }
            return (
              <Marker
                key={index}
                position={{
                  lat: item.latitude && parseFloat(item.latitude),
                  lng: item.longitude && parseFloat(item.longitude),
                }}
                icon={Square}
                title={item.Addr}
                onClick={() => props.onToggleOpen({ index })}
              >
                {props.isOpen && props.index === index && console.log("a")}
                {props.isOpen && props.index === index && (
                  <InfoBox
                    onCloseClick={props.onToggleOpen}
                    options={{ closeBoxURL: ``, enableEventPropagation: true }}
                  >
                    {/* <div
                    style={{
                      backgroundColor: `yellow`,
                      opacity: 0.75,
                      padding: `12px`,
                    }}
                  >
                    <div style={{ fontSize: `16px`, fontColor: `#08233B` }}>
                      Hello, Kaohsiung!
                    </div>
                  </div>*/}
                    <CardInfobox
                      item={item}
                      closeBtn={() => props.onToggleOpen({ index })}
                      goTo={(payload) => props.navigateToPage(payload)}
                    />
                  </InfoBox>
                )}
              </Marker>
            );
          })
        ) : (
          <Marker
            position={{
              lat: parseFloat(mapPositionLat),
              lng: parseFloat(mapPositionLng),
            }}
          />
        )}
      </GoogleMap>
    ))
  );

  const [propertyTypes, setPropertyTypes] = useState([]);

  const getPropertyTypes = async () => {
    try {
      const res = await SettingModel.fetchPropertyTypes();

      setPropertyTypes(res);
    } catch (error) {
      console.log("error ", error);
    }
  };

  useEffect(() => {
    getPropertyTypes();

    return () => {
      getPropertyTypes();
    };
  }, []);

  const [suggestions, setSuggestions] = useState([]);
  const [searching, setSearching] = useState(false);

  // find suggesstion only if user type any keyword
  useEffect(() => {
    if(search.keyword){
    SearchModel.findSimilarity(search.keyword).then((res) => {
      // matching rating is greater than 0
      if (res.bestMatch.rating > 0) {
        // copy array without duplication
        let data = cloneDeep(suggestions);

        const allInarray = data.filter(
          (values) => values == res.bestMatch.target
        );

        // Make sure the value doesn't exist already
        if (allInarray.length == 0) {
          data.push(res.bestMatch.target);
          setSuggestions(data);
        }
      }
    });
  }
  }, [search.keyword]);

  async function handleFormSubmit(event) {
    event.preventDefault();

    // if (search.keyword === "") {
    //   Swal.fire("Error", "Search keyword cannot be empty", "error");
    //   return;
    // }

    setSearching(true);

    let res = await SearchModel.search(search);
    console.log(`resss => `, res);
    props.saveSearchResults(res);

    setSearching(false);

    // this is for lazy loading
    setRequestData(res.results);

    // MLS search only
    if (res.results.length !== undefined && res.results.length > 0) {
      setSearchData(res.results);
      setSearching(false);
      // setLoading(false);

      return;
    } else {
      setSearchData([]);
    }

    if (res.results.data.length > 0) {
      setSearchData(res.results.data);
      setTempSearchData(res.results.data);
    } else {
      setSearchData([]);
      setTempSearchData([]);
    }

    setSearching(false);

    // history.push("search", { search });
  }

  const [suggestionClicked, setSuggestionClicked] = useState(false);

  const handleTyping = (value) => {
    setSearch({ ...search, keyword: value });

    if (suggestionClicked) {
      setSuggestionClicked(false);
    }
  };

  const handleSuggestionClick = (keyword) => {
    setSearch({ ...search, keyword: keyword });
    setSuggestions([]);
    setSuggestionClicked(true);
    setCount(count + 1);
  };

  const handlePriceChange = (e) => {
    const price = e.target.value.split("-");

    if (parseInt(price[0]) > 0) {
      setSearchData([]);

      let min = parseInt(price[0]);
      let max = parseInt(price[1]);

      let _data = [];

      tempSearchData.forEach((data) => {
        if (data.Orig_dol >= min && data.Orig_dol <= max) {
          _data.push(data);
        }
      });

      if (_data.length > 0) {
        setSearchData(_data);
        console.log(`_data => `, _data);
        return;
      }

      setSearchData([]);

      // console.log(`Price => ${data.Orig_dol} && min ${min} || Max ${max}`);
    } else {
      setSearchData(tempSearchData);
    }
  };

  const handleOnBlur = () => {
    setTimeout(function () {
      setSuggestionClicked(true);
      setCount(count + 1);
    }, 100);
  };

  function handleViewChange() {
    mapView ? setMapView(false) : setMapView(true);
  }

  function handlePriceSort(sort) {
    // setPriceSort(sort);

    if (sort) {
      console.log("High to Low");
      const HL = searchData.sort((a, b) => a.Orig_dol - b.Orig_dol).reverse();
      setSearchData(HL);
      setCount(count + 1);
      return;
    }

    console.log("Low to High");
    const LH = searchData.sort((a, b) => a.Orig_dol - b.Orig_dol);
    setSearchData(LH);
    setCount(count + 1);
  }

  function addToShowings(payload) {
    let storage = JSON.parse(localStorage.getItem("showings"));

    if (storage) {
      const found = storage.find(
        (pr) => pr.property_id === payload.property_id
      );

      if (found !== undefined || found) {
        Swal.fire(
          "Already Exist",
          "Property already in showings list.",
          "warning"
        );
        return;
      }
    }

    props.setShowings(payload);

    Swal.fire("Success", "Property added to showings list.", "success");
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const TITLE = process.env.REACT_APP_SITE_NAME+' - Map View'
  window.scrollTo(0,0);

  return (
    <>
      <Helmet>
            <title>{ TITLE }</title>
        </Helmet>
      <HeaderComponent />
      <div className="innerbanner">
        <div className="w-100 ib_top"></div>
      </div>

      <div className="container pb-4">
        <div className="mt-5 mb-1">
          <section className="bg_primary pt-md-4 pb-md-3 px-3 pt-3 serchengine">
            <div className="container pt-4 py-md-0">
              <Tabs variant="pills" defaultActiveKey="tab-1">
                  <Tab eventKey="tab-1" title="Residential" className="border border-4">
                      {/* <h4>Residential</h4> */}
                      <form onSubmit={handleFormSubmit}>
                          <div className="d-md-flex justify-content-between">
                              <div className="d-flex flex-column flex-md-row justify-content-betwen p-4 w-100">
                                  <Col md={2} className="">
                                  <label class="text-dark">Property Type</label>
                                  <select
                                      name="buildingType"
                                      id="building-type"
                                      value={search.buildingType}
                                      className="form-control rounded-0 w-100 form-select input_city"
                                      onChange={(e) =>
                                          setSearch({ ...search, buildingType: e.target.value })
                                      }
                                      >
                                      <option value="any">Any</option>
                                        <option value="condo">Condo</option>
                                        <option value="Att/Row/Twnhouse">Att/Row/Twnhouse</option>
                                        <option value="Cottage">Cottage</option>
                                        <option value="Detached">Detached</option>
                                        <option value="Duplex">Duplex</option>
                                        <option value="Farm">Farm</option>
                                        <option value="Fourplex">Fourplex</option>
                                        <option value="Link">Link</option>
                                        <option value="Lower Level">Lower Level</option>
                                        <option value="Mobile/Trailer">Mobile/Trailer</option>
                                        <option value="Multiplex">Multiplex</option>
                                        <option value="Other">Other</option>
                                        <option value="Rural Resid">Rural Resid</option>
                                        <option value="Semi-Detached">Semi-Detached</option>
                                        <option value="Store W/Apt/Offc">Store W/Apt/Offc</option>
                                        <option value="Triplex">Triplex</option>
                                        <option value="Upper Level">Upper Level</option>
                                        <option value="Vacant Land">Vacant Land</option>			
                                  </select>
                                  </Col>
                                  
                                  <Col md={2} className="">
                                  <label class="text-dark">Transaction Type</label>
                                  <select
                                      name="transactionType"
                                      id="transaction-type"
                                      value={search.transactionType}
                                      className="form-control rounded-0 w-100 form-select input_city"
                                      onChange={(e) =>
                                          setSearch({ ...search, transactionType: e.target.value })
                                      }
                                      >
                                      <option value="any">Any</option>
                                      <option value="Sale">Sale</option>
                                      <option value="Lease">Rent/Lease</option>
                                      <option value="Sub-Lease">Sub Lease</option>
                                  </select>
                                  </Col>
                                  
                                  <Col md={4} className="px-1">
                                  <label class="text-dark">Search by MLS #, City, Neighborhood, Zip or Addres</label>
                                  <input
                                      type="text"
                                      name="keyword"
                                      className="form-control rounded-0 w-100"
                                      placeholder="Toronto"
                                      onChange={(e) => handleTyping(e.target.value)}
                                      value={search.keyword}
                                      onBlur={() => handleOnBlur()}
                                  />
                                  <ul className="list-group">
                                      {!suggestionClicked &&
                                      suggestions.map((suggestion, index) => (
                                          <li
                                          key={index}
                                          className="list-group-item"
                                          onClick={() => handleSuggestionClick(suggestion)}
                                          >
                                          {suggestion}
                                          </li>
                                      ))}
                                  </ul>
                                  </Col>
                                  
                                  <Col md={2} className="px-2">
                                  <label class="text-dark">Minimum Price</label>
                                  <input
                                      type="number"
                                      name="minPrice"
                                      className="form-control rounded-0 w-100"
                                      placeholder=""
                                      min="0"
                                      value={search.minPrice}
                                      step="50"
                                      onChange={(e) =>
                                          setSearch({ ...search, minPrice: e.target.value })
                                      }
                                      />
                                  </Col>
                                  
                                  <Col md={2} className="">
                                  <label class="text-dark">Maximum Price</label>
                                  <input
                                      type="number"
                                      name="maxPrice"
                                      className="form-control rounded-0 w-100"
                                      placeholder=""
                                      value={search.maxPrice}
                                      min="0"
                                      step="50"
                                      onChange={(e) =>
                                          setSearch({ ...search, maxPrice: e.target.value })
                                      }
                                      />
                                  </Col>
                              </div>
                          </div>
                          <div className="d-md-flex justify-content-between">  
                          <div className="d-flex flex-column flex-md-row justify-content-betwen p-4 w-100">
                              <Col md={2} className="">
                                    <label class="text-dark">Bed Rooms</label>
                                    <select
                                      name="bed"
                                      id="bed"
                                      value={search.bed}
                                      className="form-control rounded-0 w-100 form-select input_city"
                                      onChange={(e) =>
                                        setSearch({ ...search, bed: e.target.value })
                                      }
                                    >
                                      <option value="any">Any</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                    </select>
                                  </Col>
                                  
                                  <Col md={2} className="">
                                    <label class="text-dark">Bath Rooms</label>
                                    <select
                                      name="bath"
                                      id="bath"
                                      value={search.bath}
                                      className="form-control rounded-0 w-100 form-select input_city"
                                      onChange={(e) =>
                                        setSearch({ ...search, bath: e.target.value })
                                      }
                                    >
                                      <option value="any">Any</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                    </select>
                                  </Col>
                                  
                              <Col md={2} className="">
                              <label class="text-dark">Parking</label>
                                  <select
                                  name="parking"
                                  id="parking"
                                  value={search.parking}
                                  className="form-control rounded-0 w-100 form-select input_city"
                                  onChange={(e) =>
                                      setSearch({ ...search, parking: e.target.value })
                                  }
                                  >
                                  <option value="any">Any</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  </select>
                              </Col>   
                              <Col md={4} className="">
                                  <input
                                  type="submit"
                                  name="submitbtn"
                                  value="Search Property"
                                  className="vm_bg_blue px-4 py-4 text-white"
                                  />
                              </Col>                               
                          </div>
                          </div>
                      </form>
                  </Tab>
              </Tabs> 
            </div>
          </section>
        </div>

        <div className="d-flex justify-content-end my-3">
          <Switch getValue={(value) => handleViewChange()} />
        </div>

        <div className="row" style={{ margin: 0 }}>
          {mapView && (
            <div className="col-md-6 ps-0">
              {searching && (
                <div className="map-custom-loader">
                  <div className="d-flex justify-content-center mt-4 map-loader">
                    <div className="spinner-border text-light" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                </div>
              )}
              <MyMapComponent
                isMarkerShown
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&libraries=places`}
                // googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `900px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            </div>
          )}

          <div
            className={`${
              mapView
                ? "col-md-6 ps-0 pe-0"
                : "col-md-12 estate-home-wrapper-first "
            }`}
          >
            <div
              className="search-data d-flex justify-content-between align-items-center"
              style={{
                marginBottom: 5,
                padding: "15px",
                backgroundColor: "#1a1a1a",
                color: "#fff",
              }}
            >
              <div style={{ color: "#fff" }}>
                {searchData.length} Results {" "}
                {searchText} 
                {/*For Single Family*/}
              </div>

              <div className="dropdown dropleft">
                <button
                  className="btn btn-secondary dropdown-toggle caret-off"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ backgroundColor: "#28a745" }}
                >
                  <i className="bi bi-funnel-fill"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a
                    className="dropdown-item mb-1 pb-2 border-bottom"
                    onClick={() => handlePriceSort(true)}
                  >
                    <i className="bi bi-sort-numeric-down-alt mr-2"></i> High -
                    Low
                  </a>
                  <a
                    className="dropdown-item mb-1 pb-2 border-bottom"
                    onClick={() => handlePriceSort(false)}
                  >
                    <i className="bi bi-sort-numeric-up mr-2"></i> Low - High
                  </a>
                </div>
              </div>
            </div>

            {searching && (
              <div className="text-center my-3">
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            )}

            <InfiniteScroll
              style={{
                overflowY: "scroll",
                overflowX: "hidden",
              }}
              dataLength={searchData.length} //This is important field to render the next data
              next={fetchMore}
              height={825}
              hasMore={requestData.next_page_url ? true : false}
              loader={
                <div className="text-center my-3">
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                </div>
              }
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >

              <div className={`row mt-3 featured_property_list_vertical ${!mapView && "estate-home-wrapper"}`}>
                {searchData.map((item) => {
                  return (
                    <div
                      key={item.property_id}
                      className={`${
                        mapView
                          ? "col-md-6 estate-details-item-mobile"
                          : "col-md-3 estate-details-item estate-details-item-mobile-list-view"
                      } my-2`}
                    >
                      <Card
                        item={item}
                        addToShowings={(value) => addToShowings(value)}
                      />
                    </div>
                  ); //
                })}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>
      <FooterComponent />
    </>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

const mapDispatchToProps = (dispatch) => ({
  saveSearchResults: (data) => {
    dispatch(saveSearchResults(data));
  },
  setShowings: (data) => {
    dispatch(setShowings(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EstateHome);
// export default EstateHome;
