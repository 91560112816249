import React, { useEffect, useState,Suspense,lazy } from "react";
import { Link, useHistory } from "react-router-dom";
import { cloneDeep } from "lodash";
import axios from "axios";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";

import { Container, Row, Col, Image,Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import Carousel from 'react-bootstrap/Carousel';
import OwlCarousel from "react-owl-carousel";
import "../../assets/css/home.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../assets/css/PostCard.css";

import { fetchingAndSetProperties } from "./../../store/actions/Properties/PropertiesActions";
import {
  saveSearchResults,
  saveSearchData,
  comSaveSearchData,
} from "../../store/actions/Search/SearchActions";

import HeaderComponent from "../../componets/Applications/Header/Header";
import FooterComponent from "../../componets/Applications/Footer/Footer";
import Importantlinks from "../../componets/Applications/Layouts/important_links";
import Location from "../../componets/Applications/Layouts/location";
import FeaturedCard from "../../componets/Cards/FeaturedCard";
import BrokerageCard from "../../componets/Cards/BrokerageCard";
import SearchModel from "../../Models/SearchModel";

import Banner1 from '../../assets/img/agent_main_banner1.webp'
import Banner2 from '../../assets/img/agent_main_banner2.webp'
import AgentPhoto from '../../assets/img/agent_banner_photo.webp'
import brokergaeLogo from '../../assets/img/brokerage_logo.webp'
// const BASE_URL = process.env.REACT_APP_BASE_URL;

const Home = (props) => {
  const [count, setCount] = useState(0);
  const [loadings, setLoadings] = useState({
    featuredListings: true
  });
  const [broLoadings, setBroLoadings] = useState({
    brokerageListings: true
  });
  const [featured, setFeatured] = useState([]);
  async function fetchFeaturedListings() {
    let res = await SearchModel.fetchFeaturedListings({
      propertyType: "RESEDENTIAL",
    });
    setFeatured(res);
    setLoadings({ featuredListings: false });
  }
  const [brokerage,setBrokerage] = useState([]);
  async function fetchbrokeragelistings() {
    let res = await SearchModel.fetchbrokeragelistings({
      propertyType: "RESEDENTIAL",
    });
    setBrokerage(res);
    setBroLoadings({ brokerageListings: false });
  }
  useEffect(() => {
    fetchbrokeragelistings();
    fetchFeaturedListings();
  }, []);
  // search
  const [search, setSearch] = useState({
    propertyType: "RESEDENTIAL",
    buildingType: "any",
    transactionType: "any",
    keyword: "",
    minPrice: 0,
    maxPrice: 0,
    bed: "any",
    bath: "any",    
    parking: "any",
  });  
  const [comSearch, setComSearch] = useState({
    propertyType: "COMMERCIAL",
    comBuildingType: "any",
    comTransactionType: "any",
    comKeyword: "",
    comMinPrice: 0,
    comMaxPrice: 0,    
    comBed: "any",
    comBath: "any",
    comParking: "any",
  }); 
  const [suggestions, setSuggestions] = useState([]);
  const [searching, setSearching] = useState(false);
  // find suggesstion only if user type any keyword
  useEffect(() => {
    if(search.keyword){
      SearchModel.findSimilarity(search.keyword).then((res) => {
        // matching rating is greater than 0
        if (res.bestMatch.rating > 0) {
          // copy array without duplication
          let data = cloneDeep(suggestions);
          const allInarray = data.filter(
            (values) => values === res.bestMatch.target
          );
          // Make sure the value doesn't exist already
          if (allInarray.length === 0) {
            data.push(res.bestMatch.target);
            setSuggestions(data);
          }
        }
      });
    }
  }, [search.keyword]);
  useEffect(() => {
    if(comSearch.comKeyword){
      SearchModel.findSimilarity(comSearch.comKeyword).then((res) => {
        // matching rating is greater than 0
        if (res.bestMatch.rating > 0) {
          // copy array without duplication
          let data = cloneDeep(suggestions);
          const allInarray = data.filter(
            (values) => values === res.bestMatch.target
          );
          // Make sure the value doesn't exist already
          if (allInarray.length === 0) {
            data.push(res.bestMatch.target);
            setSuggestions(data);
          }
        }
      });
    }
  }, [comSearch.comKeyword]);
  let history = useHistory();
  async function handleFormSubmit(event) {
    event.preventDefault();
    // if (search.keyword === "") {
    //   Swal.fire("Error", "Search keyword cannot be empty", "error");
    //   return;
    // }
    setSearching(true);
    let res = await SearchModel.search(search);
    //console.log(`res => `, res);
    props.saveSearchData(search);
    props.saveSearchResults(res);
    setSearching(false);
    history.push("search", { search });
  }
  async function comHandleFormSubmit(event) {
    event.preventDefault();  
    setSearching(true);
    //console.log(comSearch);
    let res = await SearchModel.comSearch(comSearch);
    props.saveSearchData(comSearch);
    props.saveSearchResults(res);
    setSearching(false);
    history.push("comsearch", { comSearch });
  }
  const [suggestionClicked, setSuggestionClicked] = useState(false);
  const handleTyping = (value,field) => {
    if(field == 'com'){
      setComSearch({ ...comSearch, comKeyword: value });
    }else{
      setSearch({ ...search, keyword: value });
    } 
    if (suggestionClicked) {
      setSuggestionClicked(false);
    }
  };
  const handleSuggestionClick = (keyword,field) => {
    if(field == 'com'){
      setComSearch({ ...comSearch, comKeyword: keyword });
    }else{
      setSearch({ ...search, keyword: keyword });
    } 
    setSuggestions([]);
    setSuggestionClicked(true);
    setCount(count + 1);
  };
  const handleOnBlur = () => {
    setTimeout(function () {
      setSuggestionClicked(true);
      setCount(count + 1);
    }, 200);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []); 

  const TITLE = process.env.REACT_APP_SITE_NAME+' - Home'
  return (
    <>
      <Helmet>
            <title>{ TITLE }</title>
        </Helmet>
      <HeaderComponent page="home" />
      <React.Fragment>
        <div>
          <Carousel>
              <Carousel.Item interval={1500} data-pause="false">
                  <Image className="d-block w-100" src={Banner1} alt="Image One"/>
                  <Carousel.Caption>
                      {/* <h3>Label for first slide</h3>
                      <p>Sample Text for Image One</p> */}
                  </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={1500} data-pause="false">
                  <Image className="d-block w-100" src={Banner2} alt="Image Two"/>
                  <Carousel.Caption>
                      {/* <h3>Label for second slide</h3>
                      <p>Sample Text for Image Two</p> */}
                  </Carousel.Caption>
              </Carousel.Item>
          </Carousel>
          <div className="position-relative bala_banner_photo_wrapper d-flex justify-content-end w-100">
              <Image src={AgentPhoto} className="bala_banner_photo"/>
          </div>
        </div>
        <Container>
          <Row className="pt-5 pb-4">
                <Col md={8} className="mt-5 d-flex align-items-start flex-column justify-content-center">
                    <h1 className="text-uppercase vm_fm_secondary vm_font_bold">Welcome to {process.env.REACT_APP_SITE_NAME}</h1>
                    <h3 className="vm_text_secondary text-uppercase vm_fm_secondary pt-4 pt-md-0">Ranked Top 100 Realtors in Canada & Worldwide</h3>
                </Col>
                <Col md={4} className="pt-4 pt-md-0 px-4">
                    <Image className="w-100" src={brokergaeLogo}/>
                </Col>
            </Row>
            <Row>
                <Col md={12} className="pb-5">
                    <p className="pt-2">Looking to buy or sell a home? Need local updated real estate information? I can help! I have the knowledge and experience that will help you to find the best home in your surrounding area or together we will sell your home, for as much as the market will bear, and as quickly as possible. Contact me with confidence! I guarantee your privacy will be respected. I offer the services on this site for free and without obligation. Why? I want to be YOUR best Realtor.</p>
                </Col>
            </Row>
            <Row>
                <Col md={3} >
                  <Link to="/buy" className="d-flex justify-content-center align-items-center flex-column" >
                  <div className="bg-buy_home"></div>                  
                    <h3 className="vm_font_bold text-uppercase pt-3">Buy a Home</h3>
                    <p align="center">Finding the right home is not always an easy task</p></Link>
                </Col>
                <Col md={3} >
                  <Link to="/calculators" className="d-flex justify-content-center align-items-center flex-column">
                    <div className="bg-mortgage_solution"></div>
                    <h3 className="vm_font_bold text-uppercase pt-3">Mortgage Solutions</h3>
                    <p align="center">Right mortgage solution can be an overwhelming task</p></Link>
                </Col>
                <Col md={3}>
                  <Link to="/sell" className="d-flex justify-content-center align-items-center flex-column">
                    <div className="bg-sell_home"></div>
                    <h3 className="vm_font_bold text-uppercase pt-3">Sell Your Home</h3>
                    <p align="center">Sell faster No Obligation Beat the best offer</p></Link>
                </Col>
                <Col md={3} >
                <Link to="/search" className="d-flex justify-content-center align-items-center flex-column"><div className="bg-renting_leasing"></div>
                    <h3 className="vm_font_bold text-uppercase pt-3">Renting/Leasing</h3>
                    <p align="center">Searching and choosing your desired condo/house</p></Link>
                </Col>

                <Col md={12} className="pt-5">
                    <h3 align="center" className="border border-danger py-4 vm_font_bold vm_text_secondary">MAKING YOUR REALTY DREAMS</h3>
                </Col>
                
                <Col md={12} className="d-flex justify-content-center pt-4">
                  <a href={process.env.REACT_APP_FB_LINK} target="_blank" rel="noopener noreferrer">
                        <div className="bg-header_socialmedia_facebook me-3"></div>
                    </a>
                    <a href={process.env.REACT_APP_TWITTER_LINK} target="_blank" rel="noopener noreferrer">
                        <div className="bg-header_socialmedia_twitter me-3"></div>
                    </a>
                    <a href={process.env.REACT_APP_YOUTUBE_LINK} target="_blank" rel="noopener noreferrer">
                        <div className="bg-header_socialmedia_youtube me-3"></div>
                    </a>
                    <a href={process.env.REACT_APP_INSTA_LINK} target="_blank" rel="noopener noreferrer">
                        <div className="bg-header_socialmedia_linkedin"></div>
                    </a>
                </Col>
            </Row>
        </Container>
        <Container>   
          <div className="py-5">
              <Tabs variant="pills" defaultActiveKey="tab-1">
                  <Tab eventKey="tab-1" title="Residential" className="border border-4">
                      {/* <h4>Residential</h4> */}
                      <form onSubmit={handleFormSubmit}>
                          <div className="d-md-flex justify-content-between">
                              <div className="d-flex flex-column flex-md-row justify-content-betwen p-4 w-100">
                                  <Col md={2} className="">
                                    <label class="text-dark">Property Type</label>
                                    <select
                                        name="buildingType"
                                        id="building-type"
                                        className="form-control rounded-0 w-100 form-select input_city"
                                        onChange={(e) =>
                                          setSearch({ ...search, buildingType: e.target.value })
                                        }
                                      >
                                        <option value="any">Any</option>
				                                <option value="condo">Condo</option>
				                                <option value="Att/Row/Twnhouse">Att/Row/Twnhouse</option>
                                        <option value="Cottage">Cottage</option>
                                        <option value="Detached">Detached</option>
                                        <option value="Duplex">Duplex</option>
                                        <option value="Farm">Farm</option>
                                        <option value="Fourplex">Fourplex</option>
                                        <option value="Link">Link</option>
                                        <option value="Lower Level">Lower Level</option>
                                        <option value="Mobile/Trailer">Mobile/Trailer</option>
                                        <option value="Multiplex">Multiplex</option>
                                        <option value="Other">Other</option>
                                        <option value="Rural Resid">Rural Resid</option>
                                        <option value="Semi-Detached">Semi-Detached</option>
                                        <option value="Store W/Apt/Offc">Store W/Apt/Offc</option>
                                        <option value="Triplex">Triplex</option>
                                        <option value="Upper Level">Upper Level</option>
                                        <option value="Vacant Land">Vacant Land</option>			
                                    </select>
                                  </Col>
                                  <Col md={2} className="">
                                    <label class="text-dark">Transaction Type</label>
                                    <select
                                        name="transactionType"
                                        id="transaction-type"
                                        className="form-control rounded-0 w-100 form-select input_city"
                                        onChange={(e) =>
                                          setSearch({ ...search, transactionType: e.target.value })
                                        }
                                      >
                                        <option value="any">Any</option>
                                        <option value="Sale">Sale</option>
                                        <option value="Lease">Rent/Lease</option>
                                        <option value="Sub-Lease">Sub Lease</option>
                                    </select>
                                  </Col>
                                  
                                  <Col md={4} className="px-1">
                                    <label class="text-dark">Search by MLS #, City, Neighborhood, Zip or Addres</label>
                                    <input
                                      type="text"
                                      name="keyword"
                                      className="form-control rounded-0 w-100"
                                      placeholder="Toronto"
                                      onChange={(e) => handleTyping(e.target.value,'res')}
                                      value={search.keyword}
                                      onBlur={() => handleOnBlur()}
                                    />
                                    <ul className="list-group">
                                      {!suggestionClicked &&
                                        suggestions.map((suggestion, index) => (
                                          <li
                                            key={index}
                                            className="list-group-item"
                                            onClick={() => handleSuggestionClick(suggestion,'res')}
                                          >
                                            {suggestion}
                                          </li>
                                        ))}
                                    </ul>
                                  </Col>
                                  
                                  <Col md={2} className="px-2">
                                    <label class="text-dark">Minimum Price</label>
                                    <input
                                        type="number"
                                        name="minPrice"
                                        className="form-control rounded-0 w-100"
                                        placeholder=""
                                        min="0"
                                        step="50"
                                        onChange={(e) =>
                                          setSearch({ ...search, minPrice: e.target.value })
                                        }
                                      />
                                  </Col>
                                  
                                  <Col md={2} className="">
                                    <label class="text-dark">Maximum Price</label>
                                    <input
                                        type="number"
                                        name="maxPrice"
                                        className="form-control rounded-0 w-100"
                                        placeholder=""
                                        min="0"
                                        step="50"
                                        onChange={(e) =>
                                          setSearch({ ...search, maxPrice: e.target.value })
                                        }
                                      />
                                  </Col>
                              </div>
                          </div>
                          <div className="d-md-flex justify-content-between">  
                            <div className="d-flex flex-column flex-md-row justify-content-betwen p-4 w-100">
                                <Col md={2} className="">
                                  <label class="text-dark">Bed Rooms</label>
                                  <select
                                    name="bed"
                                    id="bed"
                                    className="form-control rounded-0 w-100 form-select input_city"
                                    onChange={(e) =>
                                      setSearch({ ...search, bed: e.target.value })
                                    }
                                  >
                                    <option value="any">Any</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                  </select>
                                </Col>
                                
                                <Col md={2} className="">
                                  <label class="text-dark">Bath Rooms</label>
                                  <select
                                    name="bath"
                                    id="bath"
                                    className="form-control rounded-0 w-100 form-select input_city"
                                    onChange={(e) =>
                                      setSearch({ ...search, bath: e.target.value })
                                    }
                                  >
                                    <option value="any">Any</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                  </select>
                                </Col>
                                
                                <Col md={2} className="">
                                <label class="text-dark">Parking</label>
                                  <select
                                    name="parking"
                                    id="parking"
                                    className="form-control rounded-0 w-100 form-select input_city"
                                    onChange={(e) =>
                                      setSearch({ ...search, parking: e.target.value })
                                    }
                                  >
                                    <option value="any">Any</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                  </select>
                                </Col>   
                                <Col md={4} className="">
                                  <input
                                    type="submit"
                                    name="keyword"
                                    value="Search Property"
                                    className="vm_bg_blue px-4 py-4 text-white"
                                  />
                                </Col>                               
                            </div>
                          </div>
                      </form>
                  </Tab>
                  <Tab eventKey="tab-2" title="Commercial" className="border border-4">
                      {/* <h4>Residential</h4> */}
                      <form onSubmit={comHandleFormSubmit}>
                          <div className="d-md-flex justify-content-between">
                              <div className="d-flex flex-column flex-md-row justify-content-betwen p-4 w-100">
                                  <Col md={2} className="">
                                    <label class="text-dark">Property Type</label>
                                    <select
                                        name="comBuildingType"
                                        id="com-building-type"
                                        className="form-control rounded-0 w-100 form-select input_city"
                                        onChange={(e) =>
                                          setComSearch({ ...comSearch, comBuildingType: e.target.value })
                                        }
                                      >
                                        <option value="any">Any</option>
				                                <option value="Commercial/Retail">Commercial/Retail</option>
				                                <option value="Farm">Farm</option>
                                        <option value="Industrial">Industrial</option>			
                                        <option value="Investment">Investment</option>			
                                        <option value="Land">Land</option>			
                                        <option value="Office">Office</option>			
                                        <option value="Sale Of Business">Sale Of Business</option>			
                                        <option value="Store W/Apt/Office">Store W/Apt/Office</option>			
                                    </select>
                                  </Col>
                                  
                                  <Col md={2} className="">
                                    <label class="text-dark">Transaction Type</label>
                                    <select
                                        name="comTransactionType"
                                        id="com-transaction-type"
                                        className="form-control rounded-0 w-100 form-select input_city"
                                        onChange={(e) =>
                                          setComSearch({ ...comSearch, comTransactionType: e.target.value })
                                        }
                                      >
                                        <option value="any">Any</option>
                                        <option value="Sale">Sale</option>
                                        <option value="Lease">Rent/Lease</option>
                                        <option value="Sub-Lease">Sub Lease</option>
                                    </select>
                                  </Col>
                                  
                                  <Col md={4} className="px-1">
                                    <label class="text-dark">Search by MLS #, City, Neighborhood, Zip or Addres</label>
                                    <input
                                      type="text"
                                      name="comKeyword"
                                      className="form-control rounded-0 w-100"
                                      placeholder="Toronto"
                                      onChange={(e) => handleTyping(e.target.value,'com')}
                                      value={comSearch.comKeyword}
                                      onBlur={() => handleOnBlur()}
                                    />
                                    <ul className="list-group">
                                      {!suggestionClicked &&
                                        suggestions.map((suggestion, index) => (
                                          <li
                                            key={index}
                                            className="list-group-item"
                                            onClick={() => handleSuggestionClick(suggestion,'com')}
                                          >
                                            {suggestion}
                                          </li>
                                        ))}
                                    </ul>
                                  </Col>
                                  
                                  <Col md={2} className="px-2">
                                    <label class="text-dark">Minimum Price</label>
                                    <input
                                        type="number"
                                        name="comMinPrice"
                                        className="form-control rounded-0 w-100"
                                        placeholder=""
                                        min="0"
                                        step="50"
                                        onChange={(e) =>
                                          setComSearch({ ...comSearch, comMinPrice: e.target.value })
                                        }
                                      />
                                  </Col>
                                  
                                  <Col md={2} className="">
                                    <label class="text-dark">Maximum Price</label>
                                    <input
                                        type="number"
                                        name="comMaxPrice"
                                        className="form-control rounded-0 w-100"
                                        placeholder=""
                                        min="0"
                                        step="50"
                                        onChange={(e) =>
                                          setComSearch({ ...comSearch, comMaxPrice: e.target.value })
                                        }
                                      />
                                  </Col>
                              </div>
                          </div>
                          <div className="d-md-flex justify-content-between">  
                            <div className="d-flex flex-column flex-md-row justify-content-betwen p-4 w-100">
                              <Col md={2} className="">
                                  <label class="text-dark">Bed Rooms</label>
                                  <select
                                    name="comBed"
                                    id="com-bed"
                                    className="form-control rounded-0 w-100 form-select input_city"
                                    onChange={(e) =>
                                      setSearch({ ...comSearch, comBed: e.target.value })
                                    }
                                  >
                                    <option value="any">Any</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                  </select>
                                </Col>
                                
                                <Col md={2} className="">
                                  <label class="text-dark">Bath Rooms</label>
                                  <select
                                    name="comBath"
                                    id="com-bath"
                                    className="form-control rounded-0 w-100 form-select input_city"
                                    onChange={(e) =>
                                      setSearch({ ...comSearch, comBath: e.target.value })
                                    }
                                  >
                                    <option value="any">Any</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                  </select>
                                </Col>
                                
                                <Col md={2} className="">
                                <label class="text-dark">Parking</label>
                                  <select
                                    name="comParking"
                                    id="com-parking"
                                    className="form-control rounded-0 w-100 form-select input_city"
                                    onChange={(e) =>
                                      setComSearch({ ...comSearch, comParking: e.target.value })
                                    }
                                  >
                                    <option value="any">Any</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                  </select>
                                </Col>   
                                <Col md={4} className="">
                                  <input
                                    type="submit"
                                    name="comKeyword"
                                    value="Search Property"
                                    className="vm_bg_blue px-4 py-4 text-white"
                                  />
                                </Col>                               
                            </div>
                          </div>
                      </form>
                  </Tab>
              </Tabs>         
              {searching && (
                  <div className="d-flex justify-content-center align-items-center text-dark mt-5">
                    <h5 className="text-dark me-2"><b>Searching please wait</b></h5>
                    <div className="spinner-grow spinner-grow-sm" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                )}
            </div>
        </Container>
        <Suspense fallback={<div>Loading...</div>}>
          <Container>   
            <div className="">   
                <h2 align="center" className="vm_fm_secondary text-uppercase py-5">Our Brokerage's Properties</h2>

                <div className="featured_property_list_horizontal">
                    {broLoadings.brokerageListings && (
                      <div className="d-flex justify-content-center mt-4">
                        <div class="spinner-border" role="status">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                    )}

                    {brokerage &&
                      brokerage.map((item, index) => {
                        return (
                          <div key={index} className="border p-2 mb-4">
                             <BrokerageCard key={index} item={item} />
                          </div>  
                      );
                      })}
                </div>
                       
                <h2 align="center" className="vm_fm_secondary text-uppercase py-5">Featured Properties</h2>

                <div className="featured_property_list_vertical pb-5">
                  {loadings.featuredListings && (
                    <div className="d-flex justify-content-center mt-4">
                      <div class="spinner-border" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </div>
                  )}

                    <Row className="g-2">
                    {featured &&
                    featured.map((item, index) => {
                      return (
                        <Col md={3} key={index}>
                        <FeaturedCard key={index} item={item} />
                      </Col>                                          
                      );
                    })} 
                    </Row>
                </div>
            </div>
          </Container>
        </Suspense>
        <Importantlinks/>
        <Location/>       
      </React.Fragment> 
      <FooterComponent />
    </>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

const mapDispatchToProps = (dispatch) => ({
  getAllProperties: (data) => {
    dispatch(fetchingAndSetProperties(data));
  },
  saveSearchResults: (data) => {
    dispatch(saveSearchResults(data));
  },
  saveSearchData: (data) => {
    dispatch(saveSearchData(data));
  },
  comSaveSearchData: (data) => {
    dispatch(comSaveSearchData(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
