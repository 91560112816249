import React,{lazy, Suspense} from "react";
import { Container, Row, Col, } from "react-bootstrap";
import { Helmet } from "react-helmet";

import '../assets/css/aboutus.css'

import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
import aboutimg1 from '../assets/img/agent_img1.webp'

const Location = lazy(() => import('../componets/Applications/Layouts/location'));

const paraStyles = {
  color: "#949497",
};
const TITLE = process.env.REACT_APP_AGENT_NAME+' - About us'


const About = () => {
  window.scrollTo(0,0);
  return (
    <>
       <Helmet>
            <title>{ TITLE }</title>
        </Helmet>
      <HeaderComponent />
        <div>            
            <div className="vm_bg_cover banner" style={{ background: `url(${aboutimg1})` }}></div>
            <Container>
                    <Row>
                        <Col md={12}>
                            <div className="mt-5">
                                <h1 align="center" className="vm_fm_secondary text-uppercase">About us</h1>
                                <h3 align="center" className="text-uppercase vm_font_bold">Ranked Top 100 Realtors in <span className="vm_text_secondary">Canada & Worldwide</span></h3>
                                <p align="center" className="pt-2">Looking to buy or sell a home? Need local updated real estate information? I can help! I have the knowledge and experience that will help you to find the best home in your surrounding area or together we will sell your home, for as much as the market will bear, and as quickly as possible. Contact me with confidence! I guarantee your privacy will be respected. I offer the services on this site for free and without obligation. Why? I want to be YOUR best Realtor.</p>
                                <p align="center" className="pt-2">Looking to buy or sell a home? Need local updated real estate information? I can help! I have the knowledge and experience that will help you to find the best home in your surrounding area or together we will sell your home, for as much as the market will bear, and as quickly as possible. Contact me with confidence! I guarantee your privacy will be respected. I offer the services on this site for free and without obligation. Why? I want to be YOUR best Realtor.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            <Suspense fallback={<p>Loading...</p>}>
                <Location/>
            </Suspense>
        </div>
      <FooterComponent />
    </>
  );
};

export default About;
