import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { get } from "lodash";
import Swal from "sweetalert2";

import SettingModel from "../../models/SettingModel";

import TextInput from "../inputs/TextInput";

export default function PropertyForm(props) {
  const [loading, setLoading] = useState(false);

  async function create(payload) {
    try {
      setLoading(true);
      
      const res = await SettingModel.createPropertyTypes({
        action: "add",
        ...payload,
      });

      await props.fetchData();

      setLoading(false);

      props.closemodal(true);
    } catch (error) {
      console.log("error => ", error);
      props.closemodal(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      prop_type: get("", "prop_type", ""),
    },
    validationSchema: Yup.object({
      prop_type: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      create(values);
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-between align-items-center mb-5">
            <div>
              <h5>Add New Property Type</h5>
            </div>
            <div>
              <button className="btn btn-sm btn-success" type="submit">
                {loading ? (
                  <div class="text-center">
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>

          <div className="col-md-4">
            <TextInput
              id="property-type"
              label="Property Type"
              variant="outlined"
              getValue={(value) => formik.setFieldValue("prop_type", value)}
              value={formik.values.prop_type}
              onBlur={() => formik.setFieldTouched("prop_type")}
              error={
                Boolean(formik.errors.prop_type) && formik.touched.prop_type
              }
              errorMsg={formik.errors.prop_type}
              type="text"
            />
          </div>
        </div>
      </form>
    </div>
  );
}
