import React from "react";
import { Container, Col,Image } from "react-bootstrap";
import { Link } from 'react-router-dom';
import "../../../assets/css/footer.css";
import ImpLinksBg from './../../../assets/img/agent_footer_bg.webp'
import AgentPhoto from './../../../assets/img/agent_footer_photo.webp'
import { PinMap, Telephone, Envelope, Facebook, Twitter, Youtube } from 'react-bootstrap-icons';
import brokergaeFooterLogo from './../../../assets/img/homelife_footer.png'

const Footer = () => {
  return (
    <footer className="bg-dark vm_bg_cover" style={{ background: `url(${ImpLinksBg})` }}>
    <div className="vm_overlay_dark_7 h-100 w-100 pt-5">
        <Container className="py-5">
            <div className="d-md-flex justify-content-between">
                <Col md={4} className="profile d-flex justify-content-center flex-column align-items-center px-4">
                    <div className="rounded-circle vm_bg_cover photo" style={{ background: `url(${AgentPhoto})` }}></div>
                    <div className="bg-agent_name mt-4"></div>
                    <hr className="mb-3"/>
                    <h3 className="text-white my-0 desig text-center pb-4 pb-md-0">{process.env.REACT_APP_AGENT_TITLE}</h3>
                    <Image className="" src={brokergaeFooterLogo}/>

                </Col>
                <div className="border border-start-0"></div>
                <Col md={4} className="d-flex flex-column align-teims-center justify-content-center">
                    <h3 align="center" className="vm_font_bold text-white pb-4 pt-5 pt-md-0">Important Links</h3>
                    <div className="d-flex justify-content-center">
                        <div className="px-5">
                            <p><Link to="/about" className="text-white">About us</Link></p>
                            <p><Link to="/buy" className="text-white">Buy</Link></p>
                            <p><Link to="/sell" className="text-white">Sell</Link></p>
                            <p><Link to="/contact-us" className="text-white">Contact us</Link></p>
                        </div>
                        <div className="px-5">
                            <p><Link to="/search" className="text-white">Residential</Link></p>
                            <p><Link to="/comsearch" className="text-white">Commercial</Link></p>
                            <p><Link to="/estate/Toronto" className="text-white">Map Search</Link></p>
                            <p><Link to="/calculators" className="text-white">Calculators</Link></p>
                        </div>
                    </div>
                </Col>
                <div className="border border-start-0 mt-4 mt-md-0"></div>
                <Col md={4} className="d-flex flex-column align-teims-center justify-content-center">
                    <h3 align="center" className="vm_font_bold text-white pb-4 pt-5 pt-md-0">Get in Touch</h3>
                    <p align="center" className="text-white">                                    
                    <PinMap className="" color="white" size={22} />&nbsp;{process.env.REACT_APP_ADDRESS2_2}<br/>{process.env.REACT_APP_ADDRESS2_3}</p>
                    <p align="center" className="text-white"><Telephone className="" color="white" size={22} />&nbsp;{process.env.REACT_APP_PHONE_NUMBER}</p>
                    <p align="center" className="text-white"><Telephone className="" color="white" size={22} />&nbsp;{process.env.REACT_APP_PHONE_NUMBER2}</p>
                    <p align="center" className="text-white"><Envelope className="" color="white" size={22} />&nbsp;{process.env.REACT_APP_EMAIL}</p>
                </Col>
            </div>
            <div className="d-flex justify-content-center pt-5">
                <hr className="border border-top-0 w-100 mt-4" />
                <div className="d-flex px-5">               
                    <a href={process.env.REACT_APP_FB_LINK} target="_blank" rel="noopener noreferrer">
                        <div className="bg-socialmedia_facebook_white"></div>
                    </a>
                    <a href={process.env.REACT_APP_TWITTER_LINK} target="_blank" rel="noopener noreferrer">

                    <div className="bg-socialmedia_twitter_white ms-2"></div>
                    </a>
                    <a href={process.env.REACT_APP_YOUTUBE_LINK} target="_blank" rel="noopener noreferrer">

                    <div className="bg-socialmedia_youtube_white ms-2"></div>
                    </a>
                    <a href={process.env.REACT_APP_INSTA_LINK} target="_blank" rel="noopener noreferrer">

                    <div className="bg-socialmedia_linkedin_white ms-2"></div>
                    </a>
                </div>
                <hr className="border border-top-0 w-100 mt-4" />
            </div>
        </Container>
        <div className="vm_bg_secondary pb-3 pt-0 pt-md-3">
            <p align="center" className="mb-0 text-white">&copy; 2022 Copyrights {process.env.REACT_APP_AGENT_NAME}. All Rights Reserved.</p>
        </div>
    </div>
</footer>
  );
};

export default Footer;
