import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Container, Row, Col,Image } from "react-bootstrap";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
const TITLE = process.env.REACT_APP_SITE_NAME+' - Calculators'  

const Calculator = () => {
    window.scrollTo(0,0);
    return (
        <>
        <Helmet>
            <title>{ TITLE }</title>
        </Helmet>
        <HeaderComponent />
        <div>            
            <Container className="border-top py-5 mb-5">

                <h1 align="center" className="vm_fm_secondary text-uppercase pt-4 vm_font_bold">CALCULATORS</h1>

                <Link to="/landtransfer"><div class="pt-5 col-md-12"><h3 class="border  py-4 ps-3 vm_font_bold " align="left">LAND TRANSFER TAX CALCULATOR<p class="">Determine the amount of land transfer tax you will have to pay. Note that land transfer tax is applied on the sale price only.</p></h3></div></Link>

                <Link to="/mortgageloan"><div class="pt-5 col-md-12"><h3 class="border  py-4 ps-3 vm_font_bold " align="left">MORTGAGE LOAN CALCULATOR<p class="">Determine your estimated monthly payment and amortization schedule.</p></h3></div></Link>

                <Link to="/mortgageaffordability"><div class="pt-5 col-md-12"><h3 class="border  py-4 ps-3 vm_font_bold " align="left">MORTGAGE AFFORDABILITY CALCULATOR<p class="">Can you buy your dream home? Find out just how much you can afford!</p></h3></div></Link>

                <Link to="/cmhcpremium"><div class="pt-5 col-md-12"><h3 class="border  py-4 ps-3 vm_font_bold " align="left">CMHC PREMIUM CALCULATOR<p class="">A tool to help you estimate the premium payable when you are purchasing a home. Simply enter the purchase price, down payment and the amortization period.</p></h3></div></Link>
            </Container>
        </div>
      <FooterComponent />
    </>
  );
};

export default Calculator;