import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import NumberFormat from "react-number-format";

// import NoImage from "./../assets/img/no-image.png";
import NoImageFeature from "../assets/img/featured_area.webp";

import ImageViewer from "./ImageViewer";
import '../assets/css/featured_properties.css';

function FeaturedCard({ item, addToShowings }) {
  //console.log(`item => `, item);
  const history = useHistory();
  const [image, setImage] = useState({ url: "", index: 0 });
  const handleSetImage = (url, index) => setImage({ url, index });

  const [propertyDetails, setPropertyDetails] = useState();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const clickedOnImage = (item, index) => {
    // console.log(`img => `, img);
    /* setPropertyDetails(item);
    handleSetImage(prepareUrl(item.images[0].url), index);
    handleOpen(); */
  };

  function nextImage() {
    const IMGS = propertyDetails.images;
    const NEXT_INDEX = image.index + 1;

    if (IMGS.length - 1 < NEXT_INDEX) {
      handleSetImage(prepareUrl(IMGS[0].url), 0);
      return;
    }

    handleSetImage(prepareUrl(IMGS[NEXT_INDEX].url), NEXT_INDEX);
  }

  function previousImage() {
    const IMGS = propertyDetails.images;

    let NEXT_INDEX = image.index - 1;
    const ln = IMGS.length;

    if (NEXT_INDEX < 0) {
      handleSetImage(prepareUrl(IMGS[ln - 1].url), ln - 1);
      return;
    }

    handleSetImage(prepareUrl(IMGS[NEXT_INDEX].url), NEXT_INDEX);
  }

  function prepareUrl(url) {
    return `${process.env.REACT_APP_IMG_SOURCE}/${url}`;
  }

  return (
    <Link to={`/estate/details/${item.property_id}/${item.property_type}`}>
    <div key={item.property_id} className="border p-2 mb-3 shadow">

      {(item.images && item.images[0]) ?
        <div style={{ background: `url(${item.images.length >0 ? process.env.REACT_APP_IMG_SOURCE + '/' + item.images[0].url : NoImageFeature})` }} className="w-100 h-100 vm_bg_cover property_img"></div>
      :  <div style={{ background: `url(${NoImageFeature})` }} className="w-100 h-100 vm_bg_cover property_img"></div>}
      
      <div className="bg-white px-3 py-4 h-100">
          <div className="d-flex justify-content-between">
              <h3 className="vm_font_bold"><NumberFormat value={item.Orig_dol} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h3>
          </div>
          <hr className="my-2"/>
          <p className="mb-0">MLS®{item.Ml_num}</p>
          <p className="mb-0">{item.Addr}</p>
          <p className="mb-0">{item.Municipality}</p>
          <hr className="my-2"/>
          <div className="d-flex justify-content-between pt-md-3 pt-4 pt-md-2">
              <div className="d-flex">
                  <div className="bg-property_bed icon">
                      <div className="count vm_bg_primary text-white rounded-circle text-center">{item.bed_rooms}</div>
                  </div>
                  <div className="bg-property_bath ms-4 icon">
                      <div className="count vm_bg_primary text-white rounded-circle text-center">{item.bath_rooms}</div>
                  </div>
                  <div className="bg-property_park ms-4 icon">
                      <div className="count vm_bg_primary text-white rounded-circle text-center">{item.park_spcs}</div>
                  </div>
              </div>
              <div>&#10095;</div>
          </div>
      </div>
    </div>
  </Link>
  );
}

export default React.memo(FeaturedCard);
