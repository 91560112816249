import React, { useState, useEffect } from "react";
import axios from "./../../common/ajax";
import InfiniteScroll from "react-infinite-scroll-component";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
// import NoImage from "./../../assets/img/no-image.png";
// import NumberFormat from "react-number-format";
import { cloneDeep } from "lodash";
import SearchModel from "../../Models/SearchModel";
import SettingModel from "../admin/models/SettingModel";
import { savePropertyTypes,saveSearchData } from "../../store/actions/Search/SearchActions";
import { setShowings } from "../../store/actions/App/AppActions";
import { Col,Tabs, Tab,Row } from "react-bootstrap";
import HeaderComponent from "../../componets/Applications/Header/Header";
import FooterComponent from "../../componets/Applications/Footer/Footer";
import Card from "../../componets/Card";
// import CardButton from "../../componets/CardButton";
// import FeaturedCard from "../../componets/FeaturedCard";

const SearchResultsPage = (props) => {
  const location = useLocation();
  // const { locationName } = useParams();
  const [searchData, setSearchData] = useState([]);
  const [requestData, setRequestData] = useState({ next_page_url: null });
  // const [tempSearchData, setTempSearchData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(false);
 
  useEffect(() => {
    //console.log("obj", props.searchState);
    setLoading(true);

    if (props.searchState.results.length > 0) {
        //console.log(`hmm => `,);
        if (props.searchState.results.length > 1) {

            //console.log(`more than 1 => `,);
            setSearchData(props.searchState.results);
            setRequestData({
            next_page_url: props.searchState.results.next_page_url,
            });
            setLoading(false);

        }
        return;
    }

    if(props.searchState.searchData.propertyType != 'COMMERCIAL'){    
        props.searchState.searchData={
            propertyType: "COMMERCIAL",
            comBuildingType: "any",
            comTransactionType: "any",
            comKeyword: "Toronto",
            comMinPrice: 0,
            comMaxPrice: 0,    
            comBed: "any",
            comBath: "any",
            comParking: "any",
        };
    }

    //console.log(`coming? => `,);
    fetchProps();
  }, []);

  // search
    const [search, setSearch] = useState(props.searchState.searchData);   
    const fetchProps = async () => {
        if(props.searchState.searchData.propertyType == 'COMMERCIAL'){
            try {
                let _search = props.searchState.searchData;
                if (search.comKeyword === "") {
                    _search = {
                    ..._search,
                    comKeyword: "Toronto",
                    };
                }

                let res = await SearchModel.comSearch(_search);
                if (res.results.length !== undefined && res.results.length > 0) {
                    setSearchData(res.results);
                    setSearching(false);
                    setLoading(false);

                    return;
                } else {
                    setSearchData([]);
                }
                setSearchData(res.results.data);
                setRequestData({ next_page_url: res.results.next_page_url });
                setLoading(false);
                setSearching(false);
            } catch (error) {
                console.log("error =>", error);
            }
        }
    };

  const [suggestions, setSuggestions] = useState([]);
  const [searching, setSearching] = useState(false);

  // find suggesstion only if user type any keyword
  useEffect(() => {
    if(props.searchState.searchData.propertyType == 'COMMERCIAL'){
        if(search.comKeyword && search.comKeyword != ''){
            SearchModel.findSimilarity(search.comKeyword).then((res) => {
            // matching rating is greater than 0
            if (res.bestMatch.rating > 0) {
                // copy array without duplication
                let data = cloneDeep(suggestions);
                const allInarray = data.filter(
                (values) => values === res.bestMatch.target
                );
                // Make sure the value doesn't exist already
                if (allInarray.length === 0) {
                data.push(res.bestMatch.target);
                setSuggestions(data);
                }
            }
            });
        }
    }
  }, [search.comKeyword]);

  // let history = useHistory();
  async function handleFormSubmit(event) {
    event.preventDefault();

    // if (search.comKeyword === "") {
    //   Swal.fire("Error", "Search keyword cannot be empty", "error");
    //   return;
    // }

    setSearching(true);
    let res = await SearchModel.comSearch(search);
    //console.log(res);
    search.propertyType="COMMERCIAL";
    props.saveSearchData(search);

    // MLS search only
    if (res !== undefined && res.length > 0) {
      setSearchData(res);
      setSearching(false);

      return;
    } else {
      setSearchData([]);
    }

    // other searches
    if (res.results.data.length > 0) {
      setSearchData(res.results.data);
    } else {
      setSearchData([]);
    }

    setSearching(false);
  }
 
  const [suggestionClicked, setSuggestionClicked] = useState(false);

  const handleTyping = (value) => {      
    setSearch({ ...search, comKeyword: value });    
    if (suggestionClicked) {
      setSuggestionClicked(false);
    }
  };

  const handleSuggestionClick = (comKeyword) => {       
    setSearch({ ...search, comKeyword: comKeyword });    
    setSuggestions([]);
    setSuggestionClicked(true);
    setCount(true);
  };

  const fetchMore = async () => {
    try {
      if (requestData.next_page_url) {
        let _temp = cloneDeep(searchData);

        const res = await axios.post(requestData.next_page_url, search);

        //console.log(`res => `, res);

        _temp.push(...res.data.results.data);

        setSearchData([..._temp]);
        // setTempSearchData([...searchData, res.data.results.data]);

        // this is for lazy loading
        setRequestData(res.data.results);

        // setRequestData({next_page_url: res.data.results.next_page_url})

        setSearching(false);
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  const handleOnBlur = () => {
    setTimeout(function () {
      setSuggestionClicked(true);
      setCount(count + 1);
    }, 200);
  };

  function addToShowings(payload) {
    let storage = JSON.parse(localStorage.getItem("showings"));
    const found = storage.find((pr) => pr.property_id === payload.property_id);

    if (found !== undefined || found) {
      Swal.fire(
        "Already Exist",
        "Property already in showings list.",
        "warning"
      );
      return;
    }
    // return;
    props.setShowings(payload);

    Swal.fire("Success", "Property added to showings list.", "success");
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  var searchText='';
  if(search.propertyType=="COMMERCIAL"){
  if(search.comKeyword && search.comKeyword != ''){
    searchText+=search.comKeyword;
  } if(search.comBuildingType && search.comBuildingType != '' && search.comBuildingType != 'any' && search.comBuildingType != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+=search.comBuildingType;
  } if(search.comTransactionType && search.comTransactionType != '' && search.comTransactionType != 'any' && search.comTransactionType != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+=search.comTransactionType;
  }if(search.comMinPrice && search.comMinPrice != '' && search.comMinPrice != 'any' && search.comMinPrice != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+='Greater than : $'+search.comMinPrice;
  }if(search.comMaxPrice && search.comMaxPrice != '' && search.comMaxPrice != 'any' && search.comMaxPrice != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+='Less than : $'+search.comMaxPrice;
  }if(search.comBed && search.comBed != '' && search.comBed != 'any' && search.comBed != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+=search.comBed+' Bed(s)';
  }if(search.comBath && search.comBath != '' && search.comBath != 'any' && search.comBath != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+=search.comBath+' Bath(s)';
  }if(search.comParking && search.comParking != '' && search.comParking != 'any' && search.comParking != 'NaN'){
    if(searchText != ''){searchText +=', ';}
    searchText+=search.comParking+' Parking(s)';
  }
  if(searchText != ''){searchText='for '+searchText;}

  }

  const TITLE = process.env.REACT_APP_SITE_NAME+' - Commercial Properties'


  return (
    <>
      <Helmet>
          <title>{ TITLE }</title>
      </Helmet>
      <HeaderComponent />
      <div className="innerbanner">
        <div className="w-100 ib_top"></div>
      </div>
      <div className="container pb-4 my-5">
        <section className="bg_primary pb-md-0 px-3 pt-4 serchengine custom-padding">
          <div className="container pt-4 py-md-0">
            <Tabs variant="pills" defaultActiveKey="tab-1">
                <Tab eventKey="tab-1" title="Commercial" className="border border-4">
                    {/* <h4>Residential</h4> */}
                    <form onSubmit={handleFormSubmit}>
                        <div className="d-md-flex justify-content-between">
                            <div className="d-flex flex-column flex-md-row justify-content-betwen p-4 w-100">
                                <Col md={2} className="">
                                <label class="text-dark">Property Type</label>
                                <select
                                    name="comBuildingType"
                                    id="com-building-type"
                                    value={search.comBuildingType}
                                    className="form-control rounded-0 w-100 form-select input_city"
                                    onChange={(e) =>
                                        setSearch({ ...search, comBuildingType: e.target.value })
                                    }
                                    >
                                    <option value="any">Any</option>
                                    <option value="Commercial/Retail">Commercial/Retail</option>
                                    <option value="Farm">Farm</option>
                                    <option value="Industrial">Industrial</option>			
                                    <option value="Investment">Investment</option>			
                                    <option value="Land">Land</option>			
                                    <option value="Office">Office</option>			
                                    <option value="Sale Of Business">Sale Of Business</option>			
                                    <option value="Store W/Apt/Office">Store W/Apt/Office</option>			
                                </select>
                                </Col>
                                
                                <Col md={2} className="">
                                <label class="text-dark">Transaction Type</label>
                                <select
                                    name="comTransactionType"
                                    id="com-transaction-type"
                                    value={search.comTransactionType}
                                    className="form-control rounded-0 w-100 form-select input_city"
                                    onChange={(e) =>
                                        setSearch({ ...search, comTransactionType: e.target.value })
                                    }
                                    >
                                    <option value="any">Any</option>
                                    <option value="Sale">Sale</option>
                                    <option value="Lease">Rent/Lease</option>
                                    <option value="Sub-Lease">Sub Lease</option>
                                </select>
                                </Col>
                                
                                <Col md={4} className="px-1">
                                <label class="text-dark">Search by MLS #, City, Neighborhood, Zip or Addres</label>
                                <input
                                    type="text"
                                    name="comKeyword"
                                    className="form-control rounded-0 w-100"
                                    placeholder="Toronto"
                                    onChange={(e) => handleTyping(e.target.value)}
                                    value={search.comKeyword}
                                    onBlur={() => handleOnBlur()}
                                />
                                <ul className="list-group">
                                    {!suggestionClicked &&
                                    suggestions.map((suggestion, index) => (
                                        <li
                                        key={index}
                                        className="list-group-item"
                                        onClick={() => handleSuggestionClick(suggestion)}
                                        >
                                        {suggestion}
                                        </li>
                                    ))}
                                </ul>
                                </Col>
                                
                                <Col md={2} className="px-2">
                                <label class="text-dark">Minimum Price</label>
                                <input
                                    type="number"
                                    name="comMinPrice"
                                    className="form-control rounded-0 w-100"
                                    placeholder=""
                                    min="0"
                                    value={search.comMinPrice}
                                    step="50"
                                    onChange={(e) =>
                                        setSearch({ ...search, comMinPrice: e.target.value })
                                    }
                                    />
                                </Col>
                                
                                <Col md={2} className="">
                                <label class="text-dark">Maximum Price</label>
                                <input
                                    type="number"
                                    name="comMaxPrice"
                                    className="form-control rounded-0 w-100"
                                    placeholder=""
                                    value={search.comMaxPrice}
                                    min="0"
                                    step="50"
                                    onChange={(e) =>
                                        setSearch({ ...search, comMaxPrice: e.target.value })
                                    }
                                    />
                                </Col>
                            </div>
                        </div>
                        <div className="d-md-flex justify-content-between">  
                        <div className="d-flex flex-column flex-md-row justify-content-betwen p-4 w-100">
                            <Col md={2} className="">
                                  <label class="text-dark">Bed Rooms</label>
                                  <select
                                    name="comBed"
                                    id="com-bed"
                                    value={search.comBed}
                                    className="form-control rounded-0 w-100 form-select input_city"
                                    onChange={(e) =>
                                      setSearch({ ...search, comBed: e.target.value })
                                    }
                                  >
                                    <option value="any">Any</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                  </select>
                                </Col>
                                
                                <Col md={2} className="">
                                  <label class="text-dark">Bath Rooms</label>
                                  <select
                                    name="comBath"
                                    id="com-bath"
                                    value={search.comBath}
                                    className="form-control rounded-0 w-100 form-select input_city"
                                    onChange={(e) =>
                                      setSearch({ ...search, comBath: e.target.value })
                                    }
                                  >
                                    <option value="any">Any</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                  </select>
                                </Col>
                                
                            <Col md={2} className="">
                            <label class="text-dark">Parking</label>
                                <select
                                name="comParking"
                                id="com-parking"
                                value={search.comParking}
                                className="form-control rounded-0 w-100 form-select input_city"
                                onChange={(e) =>
                                    setSearch({ ...search, comParking: e.target.value })
                                }
                                >
                                <option value="any">Any</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                </select>
                            </Col>   
                            <Col md={4} className="">
                                <input
                                type="submit"
                                name="submitbtn"
                                value="Search Property"
                                className="vm_bg_blue px-4 py-4 text-white"
                                />
                            </Col>                               
                        </div>
                        </div>
                    </form>
                </Tab>
            </Tabs> 
            {searching && (
              <div className="d-flex justify-content-center align-items-center text-dark pb-3">
                <h5 className="text-dark me-2">Searching please wait</h5>
                <div className="spinner-grow spinner-grow-sm" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            )}
          </div>
        </section>

        <h4 className="my-5 text-center">
          {loading ? "Searching" : "Search"} {!loading && "Results"} {" "}
          {searchText} {loading && "..."}
        </h4>

        <InfiniteScroll
              style={{
                overflowY: "scroll",
                overflowX: "hidden",
              }}
              dataLength={searchData.length} //This is important field to render the next data
              next={fetchMore}
              height={825}
              hasMore={requestData.next_page_url ? true : false}
              loader={
                <div className="text-center my-3">
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                </div>
              }
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
        <Row className="featured_property_list_vertical g-2">
            {searchData.length > 0 ? (
              searchData.map((item, index) => {
                return (
                    <Col md={3} key={index}>
                    <Card
                      item={item}
                      addToShowings={(value) => addToShowings(value)}
                    />
                      </Col>                                          
                ); //
              })
            ) : (
              <h3 className="text-center">Data not found</h3>
            )}
          </Row>
        </InfiniteScroll>
      </div>
      <FooterComponent />
    </>
  );
};

const mapStateToProps = (state) => {
  return { searchState: state.searchState };
};

const mapDispatchToProps = (dispatch) => ({
  savePropertyTypes: (data) => {
    dispatch(savePropertyTypes(data));
  },
  setShowings: (data) => {
    dispatch(setShowings(data));
  },
  saveSearchData: (data) => {
    dispatch(saveSearchData(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsPage);
