import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Image } from "react-bootstrap";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
const TITLE = process.env.REACT_APP_SITE_NAME + ' - Land Transfer Calculator'

const LandTransfer = () => {
    window.scrollTo(0, 0);
    return (
        <>
            <Helmet>
                <title>{TITLE}</title>
                <script type="text/javascript" src="https://www.ratehub.ca/assets/js/widget-loader.js" type="text/javascript"/>
            </Helmet>
            <HeaderComponent />
            <div>
                <Container className="border-top py-5 mb-5">
                    <h1 align="center" className="vm_fm_secondary text-uppercase pt-4 vm_font_bold">LAND TRANSFER TAX CALCULATOR</h1>

                    <div className="widget" data-widget="calc-payment" data-ltt="only" data-lang="en" /><div style={{textAlign: 'right'}}>  <a href="https://www.ratehub.ca/" target="_blank" style={{display: 'inline-block', width: '80px', marginTop: '.5em'}}><img src="https://www.ratehub.ca/assets/images/widget-logo.png" style={{width: '100%'}} alt="RateHub logo" /></a></div>
                </Container>
            </div>
            <FooterComponent />
        </>
    );
};
export default LandTransfer;