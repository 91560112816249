import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { get, find } from "lodash";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import axios from "./../../common/ajax";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { setShowings } from "../../store/actions/App/AppActions";
import HeaderComponent from "../../componets/Applications/Header/Header";
import FooterComponent from "../../componets/Applications/Footer/Footer";
import LoadingButton from "../../componets/LoadingButton";

import OwlCarousel from "react-owl-carousel";
import pd_gal_1 from "./../../assets/img/pd_gal_1.png";
import agent_footer_photo from "./../../assets/img/agent_footer_photo.webp";
import EnquiryModel from "../../Models/EnquiryModel";
import Spinner from "../../componets/Spinner";
import ImageViewer from "../../componets/ImageViewer";
import moment from "moment";
import RelatedProperty from "../../componets/RelatedProperty";
import Skeleton from "@mui/material/Skeleton";

import Modal from "../../componets/Modal";
import GoogleMap from "../admin/components/GoogleMap";


const EstateDetails = (props) => {
  const { propertyId, propertyType } = useParams();

  const [image, setImage] = useState({ url: "", index: 0 });
  const handleSetImage = (url, index) => setImage({ url, index });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [loading, setLoading] = useState(0);
  const [count, setCount] = useState(false);
  // const [sent, setSent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [propertyDetails, setPropertyDetails] = useState({
    propert_details: [],
  });
  const [randomProperties, setRandomProperties] = useState([]);
  const [totalRooms, setTotalRooms] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getpropertyDetailsWithImage();
  }, [propertyId, propertyType]);

  const getpropertyDetailsWithImage = async () => {
    setIsLoading(true);
    setLoading(true);

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    await axios
      .get(`properties/details/${propertyId}/${propertyType}`, {})
      .then((res) => {
        const data = res.data.res;

        const _PD = data.propert_details[0];

        if (!_PD || _PD === null || _PD === undefined) {
          console.log(`_PD => `, _PD);
          return;
        }

        let rooms = [];
        let noOfRooms = [
          "Rm1",
          "Rm2",
          "Rm3",
          "Rm5",
          "Rm6",
          "Rm7",
          "Rm8",
          "Rm9",
          "Rm10",
          "Rm11",
          "Rm12",
        ];

        for (let r = 0; r < noOfRooms.length; r++) {
          const noOfRoom = noOfRooms[r];
          let data = {};

          for (const [key, value] of Object.entries(_PD)) {
            const MATCHED_KEY = key.split("_")[0];

            if (MATCHED_KEY === noOfRoom) {
              /*  console.log(
                `MATCHED_KEY.includes("out") => `,
                MATCHED_KEY.includes("out")
              ); */

              // console.log(`MATCHED_KEY.match(/out/g => `, key.match(/_out/g));

              if (key.includes("_dc1")) {
                data = {
                  ...data,
                  dc1: value,
                };
              } else if (key.includes("_dc2")) {
                data = {
                  ...data,
                  dc2: value,
                };
              } else if (key.includes("_dc3")) {
                data = {
                  ...data,
                  dc3: value,
                };
              } else if (key.includes("len")) {
                data = {
                  ...data,
                  len: value,
                };
              } else if (key.includes("_out")) {
                data = {
                  ...data,
                  out: value,
                };
              } else if (key.includes("wth")) {
                data = {
                  ...data,
                  wth: value,
                };
              }
            }
          }

          // console.log(`rooms => `, rooms);

          rooms.push(data);
        }

        if (res.data.randomProperties) {
          setRandomProperties(res.data.randomProperties);
        }

        setTotalRooms(rooms);
        setIsLoading(false);
        setPropertyDetails(data);
        setCount(count + 1);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setLoading(false);
      });
  };

  async function handleEnquiryForm(payload) {
    try {
      await EnquiryModel.create2({
        property_id: propertyId,
        property_type: propertyType,
        ...payload,
      });

      Swal.fire(`Done`, `Message successfully`, "success");
      setLoading(false);

      formik.setFieldValue("full_name", "");
      formik.setFieldValue("email_address", "");
      formik.setFieldValue("phone_number", "");
      formik.setFieldValue("enquiry", "");
    } catch (error) {
      console.log("Error", error);
      Swal.fire(`Error`, `Something went wrong.`, "error");
      setLoading(false);
    }
  }

  const options = {
    margin: 30,
    responsiveClass: true,
    loop: true,
    autoplay: true,
    autoplayTimeout: 1000,
    autoplayHoverPause: true,
    items: 1,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };

  const formik = useFormik({
    initialValues: {
      full_name: get("", "name", ""),
      email_address: get("", "email", ""),
      enquiry: get("", "msg", ""),
      phone_number: get("", "phone_number", ""),
    },
    validationSchema: Yup.object({
      full_name: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required("Required"),
      phone_number: Yup.string().min(10, 'Too Short!').max(15, 'Too Long!'),
      email_address: Yup.string().email('Invalid email').required('Required'),
      enquiry: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      handleEnquiryForm(values);
    },
  });

  const clickedOnImage = (img, index) => {
    // console.log(`img => `, img);
    handleSetImage(prepareUrl(img.url), index);
    handleOpen();
  };

  function nextImage() {
    const IMGS = propertyDetails.images;
    console.log("asa", IMGS);
    const NEXT_INDEX = image.index + 1;

    if (IMGS.length - 1 < NEXT_INDEX) {
      handleSetImage(prepareUrl(IMGS[0].url), 0);
      return;
    }

    handleSetImage(prepareUrl(IMGS[NEXT_INDEX].url), NEXT_INDEX);
  }

  function previousImage() {
    const IMGS = propertyDetails.images;

    let NEXT_INDEX = image.index - 1;
    const ln = IMGS.length;

    if (NEXT_INDEX < 0) {
      handleSetImage(prepareUrl(IMGS[ln - 1].url), ln - 1);
      return;
    }

    handleSetImage(prepareUrl(IMGS[NEXT_INDEX].url), NEXT_INDEX);
  }

  function prepareUrl(url) {
    return `${process.env.REACT_APP_IMG_SOURCE}/${url}`;
  }

  function addToShowings(payload) {
    let storage = JSON.parse(localStorage.getItem("showings"));

    if (storage) {
      const found = storage.find(
        (pr) => pr.property_id === payload.property_id
      );

      if (found !== undefined || found) {
        Swal.fire(
          "Already Exist",
          "Property already in showings list.",
          "warning"
        );
        return;
      }
    }

    props.setShowings(payload);

    Swal.fire("Success", "Property added to showings list.", "success");

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  /* useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []); */

  const [showMap, setShowMap] = useState(false);

  const TITLE = process.env.REACT_APP_SITE_NAME+' - '+propertyDetails.Addr


  return (
    <>
      <Helmet>
          <title>{ TITLE }</title>
      </Helmet>
      <HeaderComponent />
      <div className="innerbanner">
        <div className="w-100 ib_top"></div>
      </div>

      <section className="pt-5 pb-2 bg_ash2">
        <div className="container">
          <div className="d-flex">
            <h4 className="pb-4 px-4">
              <span className="fw_bold">Property</span> Details
            </h4>
            <div className="w-100 col pt-4">
              <div className="border w-100 border-left-0 border-right-0 border-bottom-0"></div>
            </div>
          </div>

          <OwlCarousel className="owl-carousel owl-theme" {...options}>
            {/* src={`${process.env.REACT_APP_IMG_SOURCE}/${img.url}`} */}

            {isLoading ? (
              <div>
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={250}
                  height={250}
                />
              </div>
            ) : (
              <>
                {propertyDetails.images ? (
                  propertyDetails.images.map((img, index) => (
                    <img
                      key={index}
                      src={`${process.env.REACT_APP_IMG_SOURCE}/${img.url}`}
                      className="item"
                      alt=""
                      height={150}
                      onClick={() => clickedOnImage(img, index)}
                    />
                  ))
                ) : (
                  <img src={pd_gal_1} className="item" alt="" />
                )}
              </>
            )}
          </OwlCarousel>
        </div>
      </section>

      <section className="pb-5 property_details bg_ash2">
        <div className="container">
          <div className="row">
            <div className="col-md-8 pt-4">
              <div className="bg-white p-4 shadow-sm">
                <div className="d-flex justify-content-between">
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <div>
                      <h4 className="fw_bold">
                        {propertyDetails.Addr && propertyDetails.Addr},{" "}
                        {propertyDetails.Municipality &&
                          propertyDetails.Municipality}
                      </h4>
                      <h5>MLS# {propertyDetails.Ml_num}</h5>
                      {/*  <p>ON, L0N 1S1</p> */}
                    </div>
                  )}

                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <div>
                      <h3 className="fw_bold">${propertyDetails.Orig_dol}</h3>
                      <p className="text-right">
                        est.* ${(propertyDetails.Orig_dol / 12).toFixed(2)} /mo
                      </p>
                    </div>
                  )}
                </div>

                <div className="d-flex justify-content-between pt-3">
                  <div className="d-flex">
                    <button className="d-flex border px-2 py-1 bg_none bg-white hvr-shutter-out-horizontal">
                      <span>&#10084;</span>
                      <span className="ps-2">Save</span>
                    </button>

                    <button className="d-flex border px-2 py-1 bg_none ms-2 bg-white hvr-shutter-out-horizontal">
                      <i className="bi bi-share-fill"></i>
                      <span className="ps-2">Share</span>
                    </button>

                    <button
                      className="d-flex border px-2 py-1 bg_none ms-2 bg-white hvr-shutter-out-horizontal"
                      onClick={() => setShowMap(true)}
                    >
                      <i className="bi bi-geo-alt-fill"></i>
                      <span className="ps-2">View on Map</span>
                    </button>
                  </div>
                  <h6>Added {moment(propertyDetails.created_at).fromNow()}</h6>
                </div>
              </div>

              {/* Features */}
              <div className="bg-white px-4 py-4 mt-2 shadow-sm">
                <div className="d-flex row">
                  <div className="col property_table col-md-6">
                    <div className="d-flex tb_wrapper">
                      <p className="left col">Type:</p>
                      <div className="line"></div>
                      <p className="col right">
                        {propertyDetails.Type_own1_out}
                      </p>
                    </div>
                    <div className="d-flex tb_wrapper">
                      <p className="left col">Style:</p>
                      <div className="line"></div>
                      <p className="col right">
                        {get(
                          propertyDetails.propert_details[0],
                          "Style",
                          "N/A"
                        )}
                      </p>
                    </div>
                    <div className="d-flex tb_wrapper">
                      <p className="left col">Size:</p>
                      <div className="line"></div>
                      <p className="col right">
                        {get(propertyDetails.propert_details[0], "Sqft", "N/A")}
                      </p>
                    </div>
                    <div className="d-flex tb_wrapper">
                      <p className="left col">Lot Size:</p>
                      <div className="line"></div>
                      <p className="col right">
                        {get(propertyDetails.propert_details[0], "Sqft", "N/A")}
                      </p>
                    </div>
                    <div className="d-flex tb_wrapper">
                      <p className="left col">Age:</p>
                      <div className="line"></div>
                      <p className="col right">
                        {get(
                          propertyDetails.propert_details[0],
                          "Yr_built",
                          "N/A"
                        )}
                      </p>
                    </div>
                    <div className="d-flex tb_wrapper">
                      <p className="left col">Taxes:</p>
                      <div className="line"></div>
                      <p className="col right">
                        {get(
                          propertyDetails.propert_details[0],
                          "Taxes",
                          "N/A"
                        )}
                      </p>
                    </div>
                    <div className="d-flex tb_wrapper">
                      <p className="left col">Walk Score:</p>
                      <div className="line"></div>
                      <p className="col right">
                        {get(
                          propertyDetails.propert_details[0],
                          "Walk_score",
                          "N/A"
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="property_table col-md-6">
                    <div className="d-flex tb_wrapper">
                      <p className="left col">Added:</p>
                      <div className="line"></div>
                      <p className="col right">
                        {moment(propertyDetails.created_at).format(
                          "MMM, D, YYYY"
                        )}
                      </p>
                    </div>
                    <div className="d-flex tb_wrapper">
                      <p className="left col">Updated:</p>
                      <div className="line"></div>
                      <p className="col right">
                        {moment(propertyDetails.updated_at).format(
                          "MMM, D, YYYY"
                        )}
                      </p>
                    </div>
                    <div className="d-flex tb_wrapper">
                      <p className="left col">Last Checked:</p>
                      <div className="line"></div>
                      <p className="col right">1 minute ago</p>
                    </div>
                    {/*<div className="d-flex tb_wrapper">
                      <p className="left col">MLS®#</p>
                      <div className="line"></div>
                      <p className="col right">{propertyDetails.Ml_num}</p>
                    </div>
                    <div className="d-flex tb_wrapper">
                      <p className="left col">Listed By:</p>
                      <div className="line"></div>
                      <p className="col right">
                        Re/max Rouge River Realty Ltd., Brokerage
                      </p>
                    </div>*/}
                  </div>
                </div>
              </div>             

              {/* Content */}
              <div className="p-4 bg-white shadow-sm">
                <p>{propertyDetails.Ad_text && propertyDetails.Ad_text}</p>
                {/* <span id="more">
                  <h5 className="fw_bold pt-4 pb-3">Extras</h5>
                  <p>**</p>
                </span>
                <div className="d-flex justify-content-center align-items-center pt-3">
                  <div className="border w-100 border-top-0 border-left-0 border-right-0 col"></div>
                  <button
                    onclick="myFunction()"
                    id="myBtn"
                    className="border px-3 py-2 mx-md-3"
                  >
                    Read More
                  </button>
                  <div className="border w-100 border-top-0 border-left-0 border-right-0 col"></div>
                </div> */}
              </div>

              {/* Features */}
              <div className="bg-white px-4 py-4 mt-4 shadow-sm">
                <div className="row">
                  <div className="col-md-6">
                    <div className="property_table">
                      <h5 className="fw_semi_bold pb-3 d-flex">
                        <div className="icon_image property bg_cover bg_norepeat me-2 mt-1"></div>
                        <span className="text_red">Property</span>
                      </h5>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Status:</p>
                        <div className="line"></div>
                        <p className="col right">{propertyDetails.S_r}</p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Type:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {propertyDetails.Type_own1_out}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Style:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {get(
                            propertyDetails.propert_details[0],
                            "Style",
                            "N/A"
                          )}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Area:</p>
                        <div className="line"></div>
                        <p className="col right">{propertyDetails.Area}</p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Community:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {get(
                            propertyDetails.propert_details[0],
                            "Community",
                            "N/A"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="property_table pt-4">
                      <h5 className="fw_semi_bold pb-3 d-flex">
                        <div className="icon_image inside bg_cover bg_norepeat me-2 mt-1"></div>
                        <span>Inside</span>
                      </h5>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Bedrooms:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {propertyDetails.bed_rooms
                            ? propertyDetails.bed_rooms
                            : 0}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Bathrooms:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {propertyDetails.bath_rooms
                            ? propertyDetails.bath_rooms
                            : 0}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Kitchens:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {get(
                            propertyDetails.propert_details[0],
                            "Kitchens",
                            "N/A"
                          )}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Rooms:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {get(
                            propertyDetails.propert_details[0],
                            "Rms",
                            "N/A"
                          )}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Rooms Plus:</p>
                        <div className="line"></div>
                        <p className="col right">1</p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Den/Family Room:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {get(
                            propertyDetails.propert_details[0],
                            "Den_fr",
                            "N/A"
                          )}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Air Conditioning:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {get(
                            propertyDetails.propert_details[0],
                            "A_c",
                            "N/A"
                          )}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Fireplace:</p>
                        <div className="line"></div>
                        <p className="col right">Y</p>
                      </div>
                    </div>
                    <div className="property_table pt-4">
                      <h5 className="fw_semi_bold pb-3 d-flex">
                        <div className="icon_image land bg_cover bg_norepeat me-2 mt-1"></div>
                        <span>Land</span>
                      </h5>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Fronting On:</p>
                        <div className="line"></div>
                        <p className="col right">W</p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Frontage:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {get(
                            propertyDetails.propert_details[0],
                            "Front_ft",
                            "N/A"
                          )}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Lot Depth:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {get(
                            propertyDetails.propert_details[0],
                            "Depth",
                            "N/A"
                          )}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Lot Size Units:</p>
                        <div className="line"></div>
                        <p className="col right">Feet</p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Pool:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {get(
                            propertyDetails.propert_details[0],
                            "Pool",
                            "N/A"
                          )}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Sewer:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {get(
                            propertyDetails.propert_details[0],
                            "Sewer",
                            "N/A"
                          )}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Zoning:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {get(
                            propertyDetails.propert_details[0],
                            "Zoning",
                            "N/A"
                          )}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Parcel Number:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {get(
                            propertyDetails.propert_details[0],
                            "Parcel_id",
                            "N/A"
                          )}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Cross Street:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {get(
                            propertyDetails.propert_details[0],
                            "Cross_st",
                            "N/A"
                          )}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Municipality District:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {get(
                            propertyDetails.propert_details[0],
                            "Municipality_district",
                            "N/A"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="property_table">
                      <h5 className="fw_semi_bold pb-3 d-flex">
                        <div className="icon_image fees bg_cover bg_norepeat me-2 mt-1"></div>
                        <span>Fees</span>
                      </h5>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Taxes:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {get(
                            propertyDetails.propert_details[0],
                            "Taxes",
                            "N/A"
                          )}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Tax Year:</p>
                        <div className="line"></div>
                        <p className="col right">2021</p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Tax Legal Description:</p>
                        <div className="line"></div>
                        <p className="col right">
                          Pt Lt 30 Pl 161 East Whitby; Pt Lt 31 Pl 161 East*
                        </p>
                      </div>
                    </div>
                    <div className="property_table pt-4">
                      <h5 className="fw_semi_bold pb-3 d-flex">
                        <div className="icon_image parking bg_cover bg_norepeat me-2 mt-1"></div>
                        <span>Parking</span>
                      </h5>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Driveway:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {get(
                            propertyDetails.propert_details[0],
                            "Drive",
                            "N/A"
                          )}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Garage:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {get(
                            propertyDetails.propert_details[0],
                            "Gar_type",
                            "N/A"
                          )}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Parking Places:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {get(
                            propertyDetails.propert_details[0],
                            "Park_spcs",
                            "N/A"
                          )}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Parking Total:</p>
                        <div className="line"></div>
                        <p className="col right">5.0</p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Covered Parking Places:</p>
                        <div className="line"></div>
                        <p className="col right">1.0</p>
                      </div>
                    </div>
                    <div className="property_table pt-4">
                      <h5 className="fw_semi_bold pb-3 d-flex">
                        <div className="icon_image building bg_cover bg_norepeat me-2 mt-1"></div>
                        <span>Building</span>
                      </h5>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Basement:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {get(
                            propertyDetails.propert_details[0],
                            "Bsmt1_out",
                            "N/A"
                          )}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Heating:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {get(
                            propertyDetails.propert_details[0],
                            "Heat_inc",
                            "N/A"
                          )}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Heating Fuel:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {get(
                            propertyDetails.propert_details[0],
                            "Heating",
                            "N/A"
                          )}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Water supply:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {get(
                            propertyDetails.propert_details[0],
                            "Water",
                            "N/A"
                          )}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Exterior:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {get(
                            propertyDetails.propert_details[0],
                            "Constr1_out",
                            "N/A"
                          )}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Exterior Features:</p>
                        <div className="line"></div>
                        <p className="col right">
                          {get(
                            propertyDetails.propert_details[0],
                            "Constr2_out",
                            "N/A"
                          )}
                        </p>
                      </div>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Structure:</p>
                        <div className="line"></div>
                        <p className="col right">Garden Shed</p>
                      </div>
                    </div>
                    <div className="property_table pt-4">
                      <h5 className="fw_semi_bold pb-3">Highlights</h5>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Feature:</p>
                        <div className="line"></div>
                        <p className="col right">Ravine</p>
                      </div>
                    </div>
                    {/* <div className="property_table pt-4 text-turncat">
                      <h5 className="fw_semi_bold pb-3 d-flex">
                        <div className="icon_image additional_media bg_cover bg_norepeat me-2 mt-1"></div>
                        <span>Additional Media</span>
                      </h5>
                      <div className="d-flex tb_wrapper">
                        <p className="left col">Virtual Tour:</p>
                        <div className="line"></div>
                        <p className="col right">
                          https://show.tours/478grandviewstsoshawa
                        </p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* Rooms */}
              <div className="bg-white px-4 py-4 mt-4 shadow-sm">
                {totalRooms && totalRooms.length > 0 && (
                  <>
                    <h5 className="fw_bold">Rooms</h5>
                    <hr className="my-2" />
                  </>
                )}

                {totalRooms &&
                  totalRooms.length > 0 &&
                  totalRooms.map((room, index) => (
                    <div key={index}>
                      {room.out && (
                        <div className="row">
                          <div className="col-md-2">
                            <p className="fw_semi_bold">{room.out}</p>
                          </div>
                          <div className="col-md-2">
                            {!room.wth || !room.len ? (
                              <p>N/A</p>
                            ) : (
                              <p>
                                {room.with} x {room.len}
                              </p>
                            )}
                          </div>
                          <div className="col-md-8">
                            <p>
                              {room.dc1},{room.dc2},{room.dc3}
                            </p>
                          </div>
                          <div className="col-md-12">
                            <hr className="my-2" />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>

            <div className="col-md-4 mt-4">
              <div className="p-4 form border bg-white border_shadow1 mb-4">
                <div className="d-flex justify-content-center pb-3">
                  <div
                    className="d-flex justify-content-center image bg-white border_shadow1 border bg_norepeat bg_cover bg_center"
                    style={{
                      backgroundImage: "url(img/agent_footer_photo.png)",
                    }}
                  >
                    <img src={agent_footer_photo} className="" alt="" />
                  </div>
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <h5 className="fw_semi_bold pb-3 text-center">
                    Ask about this home
                  </h5>
                  <label for="fullname" className="text-dark my-2 ">Full Name</label>
                  <input
                    type="text"
                    name="fullname"
                    id="fullname"
                    className="border w-100 p-2 username"
                    placeholder="Fullname"
                    value={formik.values.full_name}
                    onChange={(e) =>
                      formik.setFieldValue("full_name", e.target.value)
                    }
                    onBlur={() => formik.setFieldTouched("full_name")}
                  />
                  {Boolean(formik.errors.full_name) &&
                  formik.touched.full_name ? (
                    <small
                      id="full_nameError"
                      className={
                        "form-text " + Boolean(formik.errors.full_name)
                          ? "text-danger"
                          : "text-muted"
                      }
                    >
                      {formik.errors.full_name}
                    </small>
                  ) : (
                    ""
                  )}
                  <br />
                  <label for="email_address" className="text-dark my-2 ">Email</label>
                  <input
                    type="email"
                    name="email_address"
                    id="email_address"
                    className="border w-100 mt-1 p-2 email"
                    placeholder="Email Address"
                    value={formik.values.email_address}
                    onChange={(e) =>
                      formik.setFieldValue("email_address", e.target.value)
                    }
                    onBlur={() => formik.setFieldTouched("email_address")}
                  />
                  {Boolean(formik.errors.email_address) &&
                  formik.touched.email_address ? (
                    <small
                      id="email_addressError"
                      className={
                        "form-text " + Boolean(formik.errors.email_address)
                          ? "text-danger"
                          : "text-muted"
                      }
                    >
                      {formik.errors.email_address}
                    </small>
                  ) : (
                    ""
                  )}
                  <br />
                  <label for="number" className="text-dark my-2 ">Phone</label>
                  <input
                    type="text"
                    name="number"
                    id="number"
                    className="border w-100 mt-1 p-2 phone"
                    placeholder="Phone Number"
                    value={formik.values.phone_number}
                    onChange={(e) =>
                      formik.setFieldValue("phone_number", e.target.value)
                    }
                    onBlur={() => formik.setFieldTouched("phone_number")}
                  />
                  {Boolean(formik.errors.phone_number) &&
                  formik.touched.phone_number ? (
                    <small
                      id="phone_numberError"
                      className={
                        "form-text " + Boolean(formik.errors.phone_number)
                          ? "text-danger"
                          : "text-muted"
                      }
                    >
                      {formik.errors.phone_number}
                    </small>
                  ) : (
                    ""
                  )}
                  <br />
                  <label for="message" className="text-dark my-2 ">Message</label>
                  <textarea
                    name="message"
                    id="message"
                    cols="30"
                    rows="5"
                    className="border w-100 mt-1 p-2"
                    placeholder="I would like more information regarding a property at xxx street city"
                    value={formik.values.enquiry}
                    onChange={(e) =>
                      formik.setFieldValue("enquiry", e.target.value)
                    }
                    onBlur={() => formik.setFieldTouched("enquiry")}
                  ></textarea>
                  {Boolean(formik.errors.enquiry) && formik.touched.enquiry ? (
                    <small
                      id="enquiryError"
                      className={
                        "form-text " + Boolean(formik.errors.enquiry)
                          ? "text-danger"
                          : "text-muted"
                      }
                    >
                      {formik.errors.enquiry}
                    </small>
                  ) : (
                    ""
                  )}
                  <br />
                  <div class="d-grid  my-3">
                  <LoadingButton
                    title="Send Now"
                    style="danger"
                    loading={loading}
                    handleClick={() => formik.submitForm()}
                  />
                  
                </div>
                </form>

                <div className="text-center pt-1">
                  <p>or Call</p>

                  <a href={process.env.REACT_APP_PHONE_NUMBER_DIAL} className="py-1 text-dark my-2 ">
                  {process.env.REACT_APP_PHONE_NUMBER}
                  </a>
                </div>

                {/* <div className="text-center pt-1">
                  <button
                    className="btn btn-block bg_primary text-white py-2"
                    style={{ fontSize: 12 }}
                    onClick={() => addToShowings(propertyDetails)}
                  >
                    ADD TO SHOWINGS
                  </button>
                </div> */}
              </div>

              {/* Related Properties */}
              {}
              {randomProperties.length>0 && (
                <div>
                <h5 class="fw_semi_bold pb-4 text-center">Related Properties</h5>
                <div className="row related_properties">
                  {randomProperties.map((rPropertery, index) => (
                      <div key={index} className="mb-4 col-md-12 item">
                        <RelatedProperty property={rPropertery} />
                      </div>
                    ))}
                    
                </div>
                </div>
                )}   
            </div>
          </div>
        </div>
      </section>

      <ImageViewer
        open={open}
        onClose={() => handleClose()}
        previousImage={() => previousImage()}
        nextImage={() => nextImage()}
        image={image.url}
      />

      <Modal open={showMap} handleClose={() => setShowMap(false)}>
        <GoogleMap properties={[propertyDetails]} />
      </Modal>

      <FooterComponent />
    </>
  );
};

// export default EstateDetails;
const mapStateToProps = (state) => {
  //   console.log(`state => `, state);
  return { searchState: state.searchState };
};

const mapDispatchToProps = (dispatch) => ({
  setShowings: (data) => {
    dispatch(setShowings(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EstateDetails);
