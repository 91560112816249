import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { get } from "lodash";

export default function MailForm({ sendEmail }) {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      subject: get("", "subject", ""),
      msg: get("", "msg", ""),
    },
    validationSchema: Yup.object({
      subject: Yup.string().required("Required"),
      msg: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      console.log("Values => ", values);
      sendEmail(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div class="form-group">
        <label for="subject">Email Subject</label>
        <input
          type="text"
          class="form-control"
          id="subject"
          aria-describedby="subjectHelp"
          value={formik.values.subject}
          onChange={(value) =>
            formik.setFieldValue("subject", value.target.value)
          }
          onBlur={() => formik.setFieldTouched("subject")}
        />
        {Boolean(formik.errors.subject) && formik.touched.subject ? (
          <small
            id="subjectError"
            class={
              "form-text " + Boolean(formik.errors.subject)
                ? "text-danger"
                : "text-muted"
            }
          >
            {formik.errors.subject}
          </small>
        ) : (
          ""
        )}
      </div>
      <div class="form-group">
        <label for="emailMessage">Message</label>
        <textarea
          type="text"
          class="form-control"
          id="emailMessage"
          rows="5"
          value={formik.values.msg}
          onChange={(value) => formik.setFieldValue("msg", value.target.value)}
          onBlur={() => formik.setFieldTouched("msg")}
        />
        {Boolean(formik.errors.msg) && formik.touched.msg ? (
          <small
            id="msgError"
            class={
              "form-text " + Boolean(formik.errors.msg)
                ? "text-danger"
                : "text-muted"
            }
          >
            {formik.errors.msg}
          </small>
        ) : (
          ""
        )}
      </div>

      <button type="submit" class="btn btn-primary" disabled={loading}>
        {!loading ? (
          "Send"
        ) : (
          <div className="px-3">
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        )}
      </button>
    </form>
  );
}
