import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { get, times, random } from "lodash";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";

import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
import EnquiryModel from "../Models/EnquiryModel";
import ShowingsModel from "../Models/ShowingsModel";

import DatePicker from "../componets/DatePicker";
// import TextInput from "../pages/admin/components/inputs/TextInput";

import { updateShowings, setShowings } from "../store/actions/App/AppActions";

import NoImage from "./../assets/img/no-image.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #dedede",
  boxShadow: 24,
  p: 4,
};

/* const defaultValues = {
  full_name: "",
  email_address: "",
  message: "",
  phone_number: "",
  appointment: "",
};
 */
const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function Showings(props) {
  // console.log("obj", props.state.appState);
  let history = useHistory();

  // const showingsData = props.state.appState.showings;

  const [showingsData, setShowingsData] = useState(
    props.state.appState.showings
  );

  useEffect(() => {
    if (showingsData.length === 0) {
      const rawStorageData = localStorage.getItem("showings");

      console.log("hmm?");

      if (rawStorageData) {
        // _tempData = JSON.parse(rawStorageData);
        // update the local state
        setShowingsData(JSON.parse(rawStorageData));
        props.updateShowings(JSON.parse(rawStorageData));
        console.log(`JSON.parse(rawStorageData) => `, JSON.parse(rawStorageData));
        // update the global state
      }
    }

    // fetchShowing();
  }, []);

  const [loading, setLoading] = useState(false);
  // const [sent, setSent] = useState(false);
  // const [count, setCount] = useState(false);
  const [value, setValue] = useState(new Date());
  const [error, setError] = useState({
    status: false,
    msg: "You must select a valid date",
  });

  function getDateAndTime(payload) {
    // body...
    console.log("getDateAndTime", payload);
  }

  async function createAppointmentHandler(payload) {
    setLoading(true);
    try {
      let data = [];

      let url_code = times(6, () => random(35).toString(36)).join("");

      showingsData.forEach((item) => {
        const obj = {
          property_id: item.property_id,
          mls: item.Ml_num,
          address: item.Addr,
          municipality: item.Municipality,
          price: item.Orig_dol,
          propertyType: item.property_type,
          latitude: item.latitude,
          longitude: item.longitude,
        };

        data.push(obj);
      });

      let finalPayload = payload;

      finalPayload["properties"] = JSON.stringify(data);

      await EnquiryModel.createAppointment({ ...finalPayload, url_code });

      Swal.fire("Done", "Your appointment created successfully", "success");

      localStorage.removeItem("showings");
      props.updateShowings([]);
      setShowingsData([]);
      setLoading(false);
    } catch (error) {
      console.log("error ", error);
      Swal.fire("Error", "Something went wrong", "error");
      setLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      full_name: get("", "full_name", ""),
      email_address: get("", "email_address", ""),
      message: get("", "message", ""),
      phone_number: get("", "phone_number", ""),
      appointment: get("", "appointment", ""),
    },
    validationSchema: Yup.object({
      full_name: Yup.string().required("Required"),
      email_address: Yup.string().required("Required"),
      message: Yup.string().required("Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log("Values => ", values);

      createAppointmentHandler(values);
    },
  });

  function removeFromShowings(id) {
    console.log("removeFromShowings", id);

    const filtered = showingsData.filter((data) => data.property_id != id);

    props.updateShowings(filtered);
    setShowingsData(filtered);
  }

  async function fetchShowing() {
    let phoneNumber = null;
    let _tempData = null;

    const result = localStorage.getItem("phone_number");

    if (!result) {
      const number = await getPhoneNumber();
      if (number) phoneNumber = number;
    } else {
      phoneNumber = result;
    }

    if (showingsData.length === 0) {
      const rawStorageData = localStorage.getItem("showings");

      if (rawStorageData) {
        _tempData = JSON.parse(rawStorageData);
        // update the local state
        setShowingsData(JSON.parse(rawStorageData));
        props.updateShowings(JSON.parse(rawStorageData));
        // update the global state
      }
    }
    console.log("llll", phoneNumber);
    if (!phoneNumber) return;

    const res = await ShowingsModel.showing(phoneNumber);

    console.log("res", res);

    if (res.data != null) {
      // update the state
      const _showings = res.data.showings;

      let _temp = [];

      for (var i = 0; i < _showings.length; i++) {
        var srcObj = _showings[i];

        var tarObj = _tempData.find(function (obj) {
          return obj.property_id != srcObj.property_id;
        });

        if (!tarObj) {
          _temp.push(tarObj);
        }
      }

      _tempData = [..._showings, ..._temp];
    }

    // console.log("tem => ", _tempData);

    setShowingsData(_tempData);
    props.updateShowings(_tempData);

    saveShowings(_tempData, phoneNumber);
  }

  async function saveShowings(data, phoneNumber) {
    console.log("aaa", phoneNumber);

    const payload = {
      showings: data,
      phone_number: phoneNumber,
    };

    await ShowingsModel.saveShowing(payload);
    localStorage.setItem("phone_number", phoneNumber);
  }

  async function getPhoneNumber(argument) {
    return new Promise((resolve) => {
      Swal.fire({
        title: "Enter phone number to save your showings",
        input: "number",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Continue",
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          resolve(result.value);
        }
      });

      /* setTimeout(function () {
        resolve("hmmm");
      }, 1500);*/
    });
  }

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [rate, setRate] = useState(2);
  const [hover, setHover] = useState(-1);
  const [comment, setComment] = useState("");

  const rateNow = async () => {
    console.log("rate now => ", rate, comment);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);


  return (
    <>
      <HeaderComponent />

      <section className="my-5 mx-auto" style={{ width: "80%" }}>
        <div className="shadow p-4">
          {showingsData.length > 0 ? (
            <table class="table">
              <thead>
                <tr>
                  {/* <th scope="col">#</th> */}
                  <th scope="col">Image</th>
                  <th scope="col">Address</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {showingsData.map((data, index) => (
                  <tr key={index}>
                    {/*  <th scope="row">{index + 1}</th> */}
                    <td>
                      <img
                        src={`${data.images ? process.env.REACT_APP_IMG_SOURCE + '/' + data.images[0].url : NoImage}`}
                        alt="..."
                        width="100"
                      />
                    </td>
                    <td>{data.Addr}</td>
                    <td>
                      <button
                        className="btn btn-primary mx-1 mb-1"
                        onClick={() => history.push(
                          `/admin/estate/details/${data.property_id}/${data.property_type}`
                        )}
                      >
                        <i class="bi bi-eye"></i>
                      </button>

                      <button
                        className="btn btn-danger mx-1"
                        onClick={() => removeFromShowings(data.property_id)}
                      >
                        <i class="bi bi-eraser"></i>
                      </button>


                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="text-center">
              <h5 className="font-weight-bold p-3">
                There's no property in your showing list for appoinment.
              </h5>

              <button
                className="btn btn-lg bg_secondary text-white"
                onClick={() => history.push(`/estate/toronto`)}
              >
                ADD NOW
              </button>
            </div>
          )}
        </div>
      </section>

      {showingsData.length > 0 && (
        <section className="mx-auto mb-5" style={{ width: "80%" }}>
          <div className="shadow p-5">
            <h4 className="font-weight-bold text-center pb-3">
              Make an Appoinment
            </h4>
            <form onSubmit={formik.handleSubmit}>
              <div className="">
                <div className="mb-1">
                  <div class="form-group">
                    <label for="name">Your Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      aria-describedby="nameError"
                      value={formik.values.full_name}
                      onChange={(e) =>
                        formik.setFieldValue("full_name", e.target.value)
                      }
                      onBlur={() => formik.setFieldTouched("full_name")}
                    />
                    {Boolean(formik.errors.full_name) &&
                      formik.touched.full_name ? (
                      <small
                        id="nameError"
                        class={
                          "form-text " + Boolean(formik.errors.full_name)
                            ? "text-danger"
                            : "text-muted"
                        }
                      >
                        {formik.errors.full_name}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="mb-1">
                  <div class="form-group">
                    <label for="email">Email address</label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      aria-describedby="emailError"
                      value={formik.values.email_address}
                      onChange={(e) =>
                        formik.setFieldValue("email_address", e.target.value)
                      }
                      onBlur={() => formik.setFieldTouched("email_address")}
                    />
                    {Boolean(formik.errors.email_address) &&
                      formik.touched.email_address ? (
                      <small
                        id="emailError"
                        class={
                          "form-text " + Boolean(formik.errors.email_address)
                            ? "text-danger"
                            : "text-muted"
                        }
                      >
                        {formik.errors.email_address}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="mb-1">
                  <div class="form-group">
                    <label for="name">Phone</label>
                    <input
                      type="text"
                      class="form-control"
                      id="phone"
                      aria-describedby="phoneError"
                      value={formik.values.phone_number}
                      onChange={(e) =>
                        formik.setFieldValue("phone_number", e.target.value)
                      }
                      onBlur={() => formik.setFieldTouched("phone_number")}
                    />
                    {Boolean(formik.errors.phone_number) &&
                      formik.touched.phone_number ? (
                      <small
                        id="phoneError"
                        class={
                          "form-text " + Boolean(formik.errors.phone_number)
                            ? "text-danger"
                            : "text-muted"
                        }
                      >
                        {formik.errors.phone_number}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="mb-1">
                  <div class="form-group">
                    <label for="msg">Message</label>
                    <textarea
                      rows={5}
                      type="text"
                      class="form-control"
                      id="msg"
                      aria-describedby="msgError"
                      value={formik.values.message}
                      onChange={(e) =>
                        formik.setFieldValue("message", e.target.value)
                      }
                      onBlur={() => formik.setFieldTouched("message")}
                    />
                    {Boolean(formik.errors.message) &&
                      formik.touched.message ? (
                      <small
                        id="msgError"
                        class={
                          "form-text " + Boolean(formik.errors.message)
                            ? "text-danger"
                            : "text-muted"
                        }
                      >
                        {formik.errors.message}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="mb-1">
                  <DatePicker
                    value={formik.values.appointment}
                    getDateAndTime={(data) =>
                      formik.setFieldValue("appointment", data)
                    }
                  />
                  {error.status && (
                    <p className="text-danger">You must select a valid date</p>
                  )}
                </div>

                <button
                  type={"submit"}
                  className="btn bg_secondary mt-5 d-flex align-items-center"
                  disabled={loading}
                >
                  <p className="text-white me-2">
                    {loading ? "Please wait. . ." : "Send"}
                  </p>
                  {loading && (
                    <div className="">
                      <div
                        class="spinner-border spinner-border-sm text-light"
                        role="status"
                      >
                        <span class="sr-only"></span>
                      </div>
                    </div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </section>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div className="mb-3">
              <h5>Rate This Property</h5>
            </div>

            <div className="d-flex">
              <Rating
                name="hover-feedback"
                value={rate}
                precision={0.5}
                onChange={(event, newValue) => {
                  setRate(newValue);
                }}
                onChangeActive={(event, newHover) => {
                  setHover(newHover);
                }}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />
              {value !== null && (
                <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : rate]}</Box>
              )}
            </div>

            <div>
              <div class="form-group">
                <label
                  for="exampleInputEmail1"
                  className="font-weight-bold my-2"
                >
                  Comment
                </label>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="5"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>
              </div>
            </div>

            <div className="text-right">
              <button className="btn btn-success" onClick={() => rateNow()}>
                Rate Now
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      <FooterComponent />
    </>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

const mapDispatchToProps = (dispatch) => ({
  setShowings: (data) => {
    dispatch(setShowings(data));
  },
  updateShowings: (data) => {
    dispatch(updateShowings(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Showings);
// export default EstateHome;
