import React from "react";
import { Container, Row, Col, Tabs, Tab, Image } from 'react-bootstrap';
import './../../../assets/css/featured_properties.css'
import Property1 from './../../../assets/img/agent_property_1.webp'
import Property2 from '../../../assets/img/agent_property_2.webp'
import Property3 from '../../../assets/img/agent_property_3.webp'
import Property4 from '../../../assets/img/agent_property_4.webp'



class FeaturedProperties extends React.Component {
    render() {    
        return (
            <Container>   
                <div className="py-5">
                    <Tabs variant="pills" defaultActiveKey="tab-1">
                        <Tab eventKey="tab-1" title="Residential" className="border border-4">
                            {/* <h4>Residential</h4> */}
                            <form onSubmit={this.handleSubmit}>
                                <div className="d-md-flex justify-content-between">
                                    <div className="d-flex flex-column flex-md-row justify-content-betwen p-4 w-100">
                                        <Col md={4} className="">
                                        {/* <label>City, Neighbourhood, Zip or Address</label> */}
                                            <select name="city_zip" className="form-control rounded-0 w-100 form-select input_city">
                                                <option value="null">City, Neighbourhood, Zip or Address</option>
                                                <option value="Toronto">Toronto</option>
                                                <option value="Ontario">Ontario</option>
                                                <option value="Scarborough">Scarborough</option>
                                            </select>
                                        </Col>
                                        
                                        <Col md={2} className="">
                                            <select name="minprice" className="form-control rounded-0 w-100 form-select input_city">
                                                <option value="null">Min Price</option>
                                                <option value="">$100,000.00</option>
                                                <option value="">$200,000.00</option>
                                                <option value="">$300,000.00</option>
                                            </select>
                                        </Col>
                                        
                                        <Col md={2} className="">
                                            <select name="maxprice" className="form-control rounded-0 w-100 form-select input_city">
                                                <option value="null">Max Price</option>
                                                <option value="">$100,000.00</option>
                                                <option value="">$200,000.00</option>
                                                <option value="">$300,000.00</option>
                                            </select>
                                        </Col>
                                        
                                        <Col md={2} className="">
                                            <select name="beds" className="form-control rounded-0 w-100 form-select input_city">
                                                <option value="null">Beds</option>
                                                <option value="">1</option>
                                                <option value="">2</option>
                                                <option value="">3</option>
                                                <option value="">4</option>
                                                <option value="">5</option>
                                            </select>
                                        </Col>
                                        
                                        <Col md={2} className="">
                                            <select name="baths" className="form-control rounded-0 w-100 form-select input_city">
                                                <option value="null">Baths</option>
                                                <option value="">1</option>
                                                <option value="">2</option>
                                                <option value="">3</option>
                                                <option value="">4</option>
                                                <option value="">5</option>
                                            </select>
                                        </Col>
                                    </div>
                                    <div className="vm_bg_blue d-flex align-items-center py-2 justify-content-center px-4">
                                        <div className="bg-search_icon"></div>
                                    </div>
                                </div>
                            </form>
                        </Tab>
                        <Tab eventKey="tab-2" title="Detached" className="border border-4 p-4">
                            Detached Content...
                        </Tab>
                    </Tabs>

                    <h2 align="center" className="vm_fm_secondary text-uppercase py-5">Featured Properties</h2>

                    <div className="featured_property_list_horizontal">
                        <div className="border p-2 mb-4">
                            <Row className="g-2">
                                <Col md={3}>
                                    <h4 className="vm_bg_secondary text-white d-inline px-3 py-1 position-absolute">For Sale</h4>
                                    {/* <Image src={Property1} className="w-100 border-bottom border-danger border-3"/> */}
                                    <div style={{ background: `url(${Property1})` }} className="w-100 h-100 vm_bg_cover property_img"></div>
                                </Col>
                                <Col md={3}>
                                    <div className="vm_bg_ash px-3 py-4 h-100">
                                        <div className="d-flex justify-content-between">
                                            <h3 className="vm_font_bold">$8,999,000</h3>
                                            <div className="bg-logo_property_list"></div>
                                        </div>
                                        <hr className="my-2"/>
                                        <p className="mb-0">4899 Rue De La Gare, G8M 2Z2, Mistassini</p>
                                        <hr className="my-2"/>
                                        <div className="d-flex justify-content-between pt-md-3 pt-4 pt-md-2">
                                            <div className="d-flex">
                                                <div className="bg-property_bed icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">4</div>
                                                </div>
                                                <div className="bg-property_bath ms-4 icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">2</div>
                                                </div>
                                                <div className="bg-property_park ms-4 icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">2</div>
                                                </div>
                                            </div>
                                            <div>&#10095;</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} className="ps-md-3 d-flex align-items-start flex-column justify-content-between">
                                    <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s...
                                    </p>
                                    <div class="d-flex flex-wrap">
                                        <button className="btn vm_bg_ash rounded-0 d-flex align-items-center me-2 mb-1 mb-md-0">
                                            <div className="bg-gallery_icon_sm_black me-2 icon"></div>
                                            Photo Gallery
                                        </button>
                                        <button className="btn vm_bg_ash rounded-0 d-flex align-items-center me-2 mb-1 mb-md-0">
                                            <div className="bg-calculator_icon_sm_black me-2 icon"></div>
                                            Mortgage
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="border p-2 mb-4">
                            <Row className="g-2">
                                <Col md={3}>
                                    <h4 className="vm_bg_secondary text-white d-inline px-3 py-1 position-absolute">New</h4>
                                    <div style={{ background: `url(${Property3})` }} className="w-100 h-100 vm_bg_cover property_img"></div>
                                </Col>
                                <Col md={3}>
                                    <div className="px-3 py-4 h-100">
                                        <div className="d-flex justify-content-between">
                                            <h3 className="vm_font_bold">$8,999,000</h3>
                                            <div className="bg-logo_property_list"></div>
                                        </div>
                                        <hr className="my-2"/>
                                        <p className="mb-0">4899 Rue De La Gare, G8M 2Z2, Mistassini</p>
                                        <hr className="my-2"/>
                                        <div className="d-flex justify-content-between pt-md-3 pt-4 pt-md-2">
                                            <div className="d-flex">
                                                <div className="bg-property_bed icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">4</div>
                                                </div>
                                                <div className="bg-property_bath ms-4 icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">2</div>
                                                </div>
                                                <div className="bg-property_park ms-4 icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">2</div>
                                                </div>
                                            </div>
                                            <div>&#10095;</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} className="ps-md-3 d-flex align-items-start flex-column justify-content-between">
                                    <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s...
                                    </p>
                                    <div class="d-flex flex-wrap">
                                        <button className="btn vm_bg_ash rounded-0 d-flex align-items-center me-2 mb-1 mb-md-0">
                                            <div className="bg-gallery_icon_sm_black me-2 icon"></div>
                                            Photo Gallery
                                        </button>
                                        <button className="btn vm_bg_ash rounded-0 d-flex align-items-center me-2 mb-1 mb-md-0">
                                            <div className="bg-calculator_icon_sm_black me-2 icon"></div>
                                            Mortgage
                                        </button>
                                        <button className="btn vm_bg_ash rounded-0 d-flex align-items-center mb-1 mb-md-0">
                                            <div className="bg-calendar_icon_sm_black me-2 icon"></div>
                                            Appointment
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="border p-2 mb-4">
                            <Row className="g-2">
                                <Col md={3}>
                                    <h4 className="vm_bg_secondary text-white d-inline px-3 py-1 position-absolute">For Sale</h4>
                                    {/* <Image src={Property1} className="w-100 border-bottom border-danger border-3"/> */}
                                    <div style={{ background: `url(${Property1})` }} className="w-100 h-100 vm_bg_cover property_img"></div>
                                </Col>
                                <Col md={3}>
                                    <div className="vm_bg_ash px-3 py-4 h-100">
                                        <div className="d-flex justify-content-between">
                                            <h3 className="vm_font_bold">$8,999,000</h3>
                                            <div className="bg-logo_property_list"></div>
                                        </div>
                                        <hr className="my-2"/>
                                        <p className="mb-0">4899 Rue De La Gare, G8M 2Z2, Mistassini</p>
                                        <hr className="my-2"/>
                                        <div className="d-flex justify-content-between pt-md-3 pt-4 pt-md-2">
                                            <div className="d-flex">
                                                <div className="bg-property_bed icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">4</div>
                                                </div>
                                                <div className="bg-property_bath ms-4 icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">2</div>
                                                </div>
                                                <div className="bg-property_park ms-4 icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">2</div>
                                                </div>
                                            </div>
                                            <div>&#10095;</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} className="ps-md-3 d-flex align-items-start flex-column justify-content-between">
                                    <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s...
                                    </p>
                                    <div class="d-flex flex-wrap">
                                        <button className="btn vm_bg_ash rounded-0 d-flex align-items-center me-2 mb-1 mb-md-0">
                                            <div className="bg-gallery_icon_sm_black me-2 icon"></div>
                                            Photo Gallery
                                        </button>
                                        <button className="btn vm_bg_ash rounded-0 d-flex align-items-center me-2 mb-1 mb-md-0">
                                            <div className="bg-calculator_icon_sm_black me-2 icon"></div>
                                            Mortgage
                                        </button>
                                        <button className="btn vm_bg_ash rounded-0 d-flex align-items-center mb-1 mb-md-0">
                                            <div className="bg-calendar_icon_sm_black me-2 icon"></div>
                                            Appointment
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="border p-2 mb-4">
                            <Row className="g-2">
                                <Col md={3}>
                                    <h4 className="vm_bg_secondary text-white d-inline px-3 py-1 position-absolute">New</h4>
                                    <div style={{ background: `url(${Property3})` }} className="w-100 h-100 vm_bg_cover property_img"></div>
                                </Col>
                                <Col md={3}>
                                    <div className="vm_bg_ash px-3 py-4 h-100">
                                        <div className="d-flex justify-content-between">
                                            <h3 className="vm_font_bold">$8,999,000</h3>
                                            <div className="bg-logo_property_list"></div>
                                        </div>
                                        <hr className="my-2"/>
                                        <p className="mb-0">4899 Rue De La Gare, G8M 2Z2, Mistassini</p>
                                        <hr className="my-2"/>
                                        <div className="d-flex justify-content-between pt-md-3 pt-4 pt-md-2">
                                            <div className="d-flex">
                                                <div className="bg-property_bed icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">4</div>
                                                </div>
                                                <div className="bg-property_bath ms-4 icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">2</div>
                                                </div>
                                                <div className="bg-property_park ms-4 icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">2</div>
                                                </div>
                                            </div>
                                            <div>&#10095;</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} className="ps-md-3 d-flex align-items-start flex-column justify-content-between">
                                    <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s...
                                    </p>
                                    <div class="d-flex flex-wrap">
                                        <button className="btn vm_bg_ash rounded-0 d-flex align-items-center me-2 mb-1 mb-md-0">
                                            <div className="bg-gallery_icon_sm_black me-2 icon"></div>
                                            Photo Gallery
                                        </button>
                                        <button className="btn vm_bg_ash rounded-0 d-flex align-items-center me-2 mb-1 mb-md-0">
                                            <div className="bg-calculator_icon_sm_black me-2 icon"></div>
                                            Mortgage
                                        </button>
                                        <button className="btn vm_bg_ash rounded-0 d-flex align-items-center mb-1 mb-md-0">
                                            <div className="bg-calendar_icon_sm_black me-2 icon"></div>
                                            Appointment
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    
                    <h5 align="center" className="vm_text_blue pt-4 pb-5">You are viewing 10,992 out of 16,479 Listings, Quick <span className="vm_font_bold">Sign Up</span> for full access</h5>

                    <div className="featured_property_list_vertical pb-5">
                        <Row className="g-2">
                            <Col md={3}>
                                <div className="border p-2 mb-3 shadow">
                                    <h4 className="vm_bg_secondary text-white d-inline px-3 py-1 position-absolute">For Sale</h4>
                                    {/* <Image src={Property1} className="w-100 border-bottom border-danger border-3"/> */}
                                    <div style={{ background: `url(${Property4})` }} className="w-100 h-100 vm_bg_cover property_img"></div>

                                    <div className="px-3 py-4 h-100 bg-white">
                                        <div className="d-flex justify-content-between">
                                            <h3 className="vm_font_bold">$8,999,000</h3>
                                            <div className="bg-logo_property_list"></div>
                                        </div>
                                        <hr className="my-2"/>
                                        <p className="mb-0">4899 Rue De La Gare, G8M 2Z2, Mistassini</p>
                                        <hr className="my-2"/>
                                        <div className="d-flex justify-content-between pt-md-3 pt-4 pt-md-2">
                                            <div className="d-flex">
                                                <div className="bg-property_bed icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">4</div>
                                                </div>
                                                <div className="bg-property_bath ms-4 icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">2</div>
                                                </div>
                                                <div className="bg-property_park ms-4 icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">2</div>
                                                </div>
                                            </div>
                                            <div>&#10095;</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="border p-2 mb-3 shadow">
                                    <h4 className="vm_bg_secondary text-white d-inline px-3 py-1 position-absolute">For Sale</h4>
                                    {/* <Image src={Property1} className="w-100 border-bottom border-danger border-3"/> */}
                                    <div style={{ background: `url(${Property2})` }} className="w-100 h-100 vm_bg_cover property_img"></div>

                                    <div className="bg-white px-3 py-4 h-100">
                                        <div className="d-flex justify-content-between">
                                            <h3 className="vm_font_bold">$8,999,000</h3>
                                            <div className="bg-logo_property_list"></div>
                                        </div>
                                        <hr className="my-2"/>
                                        <p className="mb-0">4899 Rue De La Gare, G8M 2Z2, Mistassini</p>
                                        <hr className="my-2"/>
                                        <div className="d-flex justify-content-between pt-md-3 pt-4 pt-md-2">
                                            <div className="d-flex">
                                                <div className="bg-property_bed icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">4</div>
                                                </div>
                                                <div className="bg-property_bath ms-4 icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">2</div>
                                                </div>
                                                <div className="bg-property_park ms-4 icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">2</div>
                                                </div>
                                            </div>
                                            <div>&#10095;</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="border p-2 mb-3 shadow">
                                    <h4 className="vm_bg_secondary text-white d-inline px-3 py-1 position-absolute">For Sale</h4>
                                    {/* <Image src={Property1} className="w-100 border-bottom border-danger border-3"/> */}
                                    <div style={{ background: `url(${Property3})` }} className="w-100 h-100 vm_bg_cover property_img"></div>

                                    <div className="px-3 py-4 h-100 bg-white">
                                        <div className="d-flex justify-content-between">
                                            <h3 className="vm_font_bold">$8,999,000</h3>
                                            <div className="bg-logo_property_list"></div>
                                        </div>
                                        <hr className="my-2"/>
                                        <p className="mb-0">4899 Rue De La Gare, G8M 2Z2, Mistassini</p>
                                        <hr className="my-2"/>
                                        <div className="d-flex justify-content-between pt-md-3 pt-4 pt-md-2">
                                            <div className="d-flex">
                                                <div className="bg-property_bed icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">4</div>
                                                </div>
                                                <div className="bg-property_bath ms-4 icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">2</div>
                                                </div>
                                                <div className="bg-property_park ms-4 icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">2</div>
                                                </div>
                                            </div>
                                            <div>&#10095;</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="border p-2 mb-3 shadow">
                                    <h4 className="vm_bg_secondary text-white d-inline px-3 py-1 position-absolute">For Sale</h4>
                                    {/* <Image src={Property1} className="w-100 border-bottom border-danger border-3"/> */}
                                    <div style={{ background: `url(${Property1})` }} className="w-100 h-100 vm_bg_cover property_img"></div>

                                    <div className="bg-white px-3 py-4 h-100">
                                        <div className="d-flex justify-content-between">
                                            <h3 className="vm_font_bold">$8,999,000</h3>
                                            <div className="bg-logo_property_list"></div>
                                        </div>
                                        <hr className="my-2"/>
                                        <p className="mb-0">4899 Rue De La Gare, G8M 2Z2, Mistassini</p>
                                        <hr className="my-2"/>
                                        <div className="d-flex justify-content-between pt-md-3 pt-4 pt-md-2">
                                            <div className="d-flex">
                                                <div className="bg-property_bed icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">4</div>
                                                </div>
                                                <div className="bg-property_bath ms-4 icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">2</div>
                                                </div>
                                                <div className="bg-property_park ms-4 icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">2</div>
                                                </div>
                                            </div>
                                            <div>&#10095;</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="border p-2 mb-3 shadow">
                                    <h4 className="vm_bg_secondary text-white d-inline px-3 py-1 position-absolute">For Sale</h4>
                                    {/* <Image src={Property1} className="w-100 border-bottom border-danger border-3"/> */}
                                    <div style={{ background: `url(${Property4})` }} className="w-100 h-100 vm_bg_cover property_img"></div>

                                    <div className="bg-white px-3 py-4 h-100">
                                        <div className="d-flex justify-content-between">
                                            <h3 className="vm_font_bold">$8,999,000</h3>
                                            <div className="bg-logo_property_list"></div>
                                        </div>
                                        <hr className="my-2"/>
                                        <p className="mb-0">4899 Rue De La Gare, G8M 2Z2, Mistassini</p>
                                        <hr className="my-2"/>
                                        <div className="d-flex justify-content-between pt-md-3 pt-4 pt-md-2">
                                            <div className="d-flex">
                                                <div className="bg-property_bed icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">4</div>
                                                </div>
                                                <div className="bg-property_bath ms-4 icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">2</div>
                                                </div>
                                                <div className="bg-property_park ms-4 icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">2</div>
                                                </div>
                                            </div>
                                            <div>&#10095;</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="border p-2 mb-3 shadow">
                                    <h4 className="vm_bg_secondary text-white d-inline px-3 py-1 position-absolute">For Sale</h4>
                                    {/* <Image src={Property1} className="w-100 border-bottom border-danger border-3"/> */}
                                    <div style={{ background: `url(${Property2})` }} className="w-100 h-100 vm_bg_cover property_img"></div>

                                    <div className="bg-white px-3 py-4 h-100">
                                        <div className="d-flex justify-content-between">
                                            <h3 className="vm_font_bold">$8,999,000</h3>
                                            <div className="bg-logo_property_list"></div>
                                        </div>
                                        <hr className="my-2"/>
                                        <p className="mb-0">4899 Rue De La Gare, G8M 2Z2, Mistassini</p>
                                        <hr className="my-2"/>
                                        <div className="d-flex justify-content-between pt-md-3 pt-4 pt-md-2">
                                            <div className="d-flex">
                                                <div className="bg-property_bed icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">4</div>
                                                </div>
                                                <div className="bg-property_bath ms-4 icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">2</div>
                                                </div>
                                                <div className="bg-property_park ms-4 icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">2</div>
                                                </div>
                                            </div>
                                            <div>&#10095;</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="border p-2 mb-3 shadow">
                                    <h4 className="vm_bg_secondary text-white d-inline px-3 py-1 position-absolute">For Sale</h4>
                                    {/* <Image src={Property1} className="w-100 border-bottom border-danger border-3"/> */}
                                    <div style={{ background: `url(${Property3})` }} className="w-100 h-100 vm_bg_cover property_img"></div>

                                    <div className="bg-white px-3 py-4 h-100">
                                        <div className="d-flex justify-content-between">
                                            <h3 className="vm_font_bold">$8,999,000</h3>
                                            <div className="bg-logo_property_list"></div>
                                        </div>
                                        <hr className="my-2"/>
                                        <p className="mb-0">4899 Rue De La Gare, G8M 2Z2, Mistassini</p>
                                        <hr className="my-2"/>
                                        <div className="d-flex justify-content-between pt-md-3 pt-4 pt-md-2">
                                            <div className="d-flex">
                                                <div className="bg-property_bed icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">4</div>
                                                </div>
                                                <div className="bg-property_bath ms-4 icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">2</div>
                                                </div>
                                                <div className="bg-property_park ms-4 icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">2</div>
                                                </div>
                                            </div>
                                            <div>&#10095;</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="border p-2 mb-3 shadow">
                                    <h4 className="vm_bg_secondary text-white d-inline px-3 py-1 position-absolute">For Sale</h4>
                                    {/* <Image src={Property1} className="w-100 border-bottom border-danger border-3"/> */}
                                    <div style={{ background: `url(${Property1})` }} className="w-100 h-100 vm_bg_cover property_img"></div>

                                    <div className="bg-white px-3 py-4 h-100">
                                        <div className="d-flex justify-content-between">
                                            <h3 className="vm_font_bold">$8,999,000</h3>
                                            <div className="bg-logo_property_list"></div>
                                        </div>
                                        <hr className="my-2"/>
                                        <p className="mb-0">4899 Rue De La Gare, G8M 2Z2, Mistassini</p>
                                        <hr className="my-2"/>
                                        <div className="d-flex justify-content-between pt-md-3 pt-4 pt-md-2">
                                            <div className="d-flex">
                                                <div className="bg-property_bed icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">4</div>
                                                </div>
                                                <div className="bg-property_bath ms-4 icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">2</div>
                                                </div>
                                                <div className="bg-property_park ms-4 icon">
                                                    <div className="count vm_bg_primary text-white rounded-circle text-center">2</div>
                                                </div>
                                            </div>
                                            <div>&#10095;</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
        )
    }
}

export default FeaturedProperties;