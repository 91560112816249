import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col,Image } from "react-bootstrap";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
import innerBanner from "./../assets/img/agent_main_banner5.webp"
import { PinMap, Telephone, Envelope, Facebook, Twitter, Youtube } from 'react-bootstrap-icons';
import { useFormik } from "formik";
import * as Yup from "yup";
import { get, find } from "lodash";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import axios from "../common/ajax";
import { useParams } from "react-router-dom";
import LoadingButton from "../componets/LoadingButton";

import ContactModel from "../Models/ContactModel";
import Spinner from "../componets/Spinner";

const TITLE = process.env.REACT_APP_SITE_NAME+' - Contact Us'  
const Contactus = (props) => {
    const [loading, setLoading] = useState(0);


async function handleContactForm(payload) {
    try {
      await ContactModel.create({
        ...payload,
      });

      Swal.fire(`Done`, `Message successfully`, "success");
      setLoading(false);

      formik.setFieldValue("firstname", "");
      formik.setFieldValue("lastname", "");
      formik.setFieldValue("email_address", "");
      formik.setFieldValue("phone_number", "");
      formik.setFieldValue("enquiry", "");
    } catch (error) {
      console.log("Error", error);
      Swal.fire(`Error`, `Something went wrong.`, "error");
      setLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
        firstname: get("", "firstname", ""),
        lastname: get("", "lastname", ""),
      email_address: get("", "email_address", ""),
      enquiry: get("", "enquiry", ""),
      phone_number: get("", "phone_number", ""),
    },
    validationSchema: Yup.object({
        firstname: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required("Required"),
        lastname: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required("Required"),
      phone_number: Yup.string().min(10, 'Too Short!').max(15, 'Too Long!'),
      email_address: Yup.string().email('Invalid email').required('Required'),
      enquiry: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      handleContactForm(values);
    },
  });
    return (
        <>
        <Helmet>
            <title>{ TITLE }</title>
        </Helmet>
        <HeaderComponent />
        <div>           
            <div>
                <Image src={innerBanner} className="w-100"/>
            </div>
            <section className="bg-light">
                <Container className="border-top">
                    <h1 className="vm_fm_secondary text-start text-md-center pb-3 pb-md-0 vm_font_bold text-uppercase pt-5 mt-4">Contact us</h1> 
                    <Row>
                        <Col md={6} className="py-md-5"> 
                            <div className="bg-white p-md-5 p-3 h-100">
                                <h4 className="text-uppercase vm_font_bold pb-0">{process.env.REACT_APP_AGENT_NAME}</h4>
                                <p className="text-dark pb-3">{process.env.REACT_APP_AGENT_TITLE}</p>
                                <div className="d-flex align-items-start pt-3">
                                    <PinMap className="mt-2" color="black" size={22} />
                                    <p className="text-dark ps-3 mb-0">{process.env.REACT_APP_ADDRESS2_2}<br/>{process.env.REACT_APP_ADDRESS2_3}</p>
                                </div>
                                <div className="d-flex align-items-center pt-3">
                                    <Telephone color="black" size={22} />
                                    <p className="text-dark ps-3 mb-0">{process.env.REACT_APP_PHONE_NUMBER} / {process.env.REACT_APP_PHONE_NUMBER2}</p>
                                </div>
                                <div className="d-flex align-items-center pt-3">
                                    <Envelope color="black" size={22} />
                                    <p className="text-dark ps-3 mb-0">{process.env.REACT_APP_EMAIL}</p>
                                </div>
                                <div className="d-flex pt-4">
                                    <Facebook color="black" size={26}/>
                                    <Twitter className="ms-2" color="black" size={27}/>
                                    <Youtube className="ms-2" color="black" size={30}/>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} className="py-5"> 
                            <div className="bg-white p-md-5 p-3 h-100">
                                <h4 className="text-uppercase vm_font_bold pb-4">Inquiry Form</h4>
                                <form onSubmit={formik.handleSubmit}>
                                    <Row className="my-3">
                                        <Col md={6}>
                                            <input
                                                type="text"
                                                name="firstname"
                                                id="firstname"
                                                className="form-control rounded-0 col me-md-2 mb-3 mb-md-0"
                                                placeholder="First Name"
                                                value={formik.values.firstname}
                                                onChange={(e) =>
                                                formik.setFieldValue("firstname", e.target.value)
                                                }
                                                onBlur={() => formik.setFieldTouched("firstname")}
                                            />
                                            {Boolean(formik.errors.firstname) &&
                                            formik.touched.firstname ? (
                                                <small
                                                id="firstnameError"
                                                className={
                                                    "form-text " + Boolean(formik.errors.firstname)
                                                    ? "text-danger"
                                                    : "text-muted"
                                                }
                                                >
                                                {formik.errors.firstname}
                                                </small>
                                            ) : (
                                                ""
                                            )}
                                        </Col>
                                        <Col md={6}>
                                        <input
                                            type="text"
                                            name="lastname"
                                            id="lastname"
                                            className="form-control rounded-0 col me-md-2 mb-3 mb-md-0"
                                            placeholder="Last Name"
                                            value={formik.values.lastname}
                                            onChange={(e) =>
                                            formik.setFieldValue("lastname", e.target.value)
                                            }
                                            onBlur={() => formik.setFieldTouched("lastname")}
                                        />
                                        {Boolean(formik.errors.lastname) &&
                                        formik.touched.lastname ? (
                                            <small
                                            id="lastnameError"
                                            className={
                                                "form-text " + Boolean(formik.errors.lastname)
                                                ? "text-danger"
                                                : "text-muted"
                                            }
                                            >
                                            {formik.errors.lastname}
                                            </small>
                                        ) : (
                                            ""
                                        )}
                                        </Col>
                                    </Row>
                                    <Row className="my-3">
                                        <Col md={6}>
                                            <input
                                                type="text"
                                                name="phone_number"
                                                id="phone_number"
                                                className="form-control rounded-0 col me-md-2 mb-3 mb-md-0"
                                                placeholder="Phone Number"
                                                value={formik.values.phone_number}
                                                onChange={(e) =>
                                                formik.setFieldValue("phone_number", e.target.value)
                                                }
                                                onBlur={() => formik.setFieldTouched("phone_number")}
                                            />
                                            {Boolean(formik.errors.phone_number) &&
                                            formik.touched.phone_number ? (
                                                <small
                                                id="phone_numberError"
                                                className={
                                                    "form-text " + Boolean(formik.errors.phone_number)
                                                    ? "text-danger"
                                                    : "text-muted"
                                                }
                                                >
                                                {formik.errors.phone_number}
                                                </small>
                                            ) : (
                                                ""
                                            )}
                                        </Col>
                                        <Col md={6}>
                                            <input
                                                type="text"
                                                name="email_address"
                                                id="email_address"
                                                className="form-control rounded-0 col me-md-2 mb-3 mb-md-0"
                                                placeholder="Email"
                                                value={formik.values.email_address}
                                                onChange={(e) =>
                                                formik.setFieldValue("email_address", e.target.value)
                                                }
                                                onBlur={() => formik.setFieldTouched("email_address")}
                                            />
                                            {Boolean(formik.errors.email_address) &&
                                            formik.touched.email_address ? (
                                                <small
                                                id="email_addressError"
                                                className={
                                                    "form-text " + Boolean(formik.errors.email_address)
                                                    ? "text-danger"
                                                    : "text-muted"
                                                }
                                                >
                                                {formik.errors.email_address}
                                                </small>
                                            ) : (
                                                ""
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="my-3">
                                        <Col md={12} >
                                        <textarea
                                            name="message"
                                            id="message"
                                            cols="30"
                                            rows="5"
                                            className="form-control rounded-0"
                                            placeholder="Message"
                                            value={formik.values.enquiry}
                                            onChange={(e) =>
                                            formik.setFieldValue("enquiry", e.target.value)
                                            }
                                            onBlur={() => formik.setFieldTouched("enquiry")}
                                        ></textarea>
                                        {Boolean(formik.errors.enquiry) && formik.touched.enquiry ? (
                                            <small
                                            id="enquiryError"
                                            className={
                                                "form-text " + Boolean(formik.errors.enquiry)
                                                ? "text-danger"
                                                : "text-muted"
                                            }
                                            >
                                            {formik.errors.enquiry}
                                            </small>
                                        ) : (
                                            ""
                                        )}                                        
                                    </Col>
                                    </Row>
                                    <div class="d-grid  my-3">
                                        <LoadingButton
                                            className="vm_bg_secondary text-white border-0 rounded-0 px-3 py-2 mt-3"
                                            title="Submit"
                                            style="danger"
                                            loading={loading}
                                            handleClick={() => formik.submitForm()}
                                        />
                                    </div>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <iframe src={process.env.REACT_APP_IFRAME_SRC} width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>    
            </section>
        </div>
      <FooterComponent />
    </>
  );
};

export default Contactus;