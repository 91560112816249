import React from "react";
import { Link, } from "react-router-dom";
import { Row, Col,Image } from "react-bootstrap";
import NumberFormat from "react-number-format";
import NoImage from "../../assets/img/featured_area.webp";

//import CardButton from "./CardButton";

function BrokerageCard({ item }) {
  //console.log("item", item);
  return (
    <Link to={`/estate/details/${item.property_id}/${item.property_type}`}>
      <Row className="g-2" key={item.property_id}>
      <Col md={3}>

        {(item.images && item.images[0]) ?
           <div style={{ background: `url(${item.images.length >0 ? process.env.REACT_APP_IMG_SOURCE + '/' + item.images[0].url : NoImage})` }} className="w-100 h-100 vm_bg_cover property_img"></div>
        :   <div style={{ background: `url(${NoImage})` }} className="w-100 h-100 vm_bg_cover property_img"></div>}
        </Col>
        <Col md={3}>
            <div className="vm_bg_ash px-3 py-4 h-100">
                <div className="d-flex justify-content-between">
                    <h3 className="vm_font_bold"><NumberFormat value={item.Orig_dol} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h3>
                    <div className="bg-logo_property_list"></div>
                </div>
                <hr className="my-2"/>
                <p className="mb-0"> MLS®{item.Ml_num}</p>
                <p className="mb-0">{item.Addr}</p>
                <p className="mb-0">{item.Municipality}</p>
                <hr className="my-2"/>
                <div className="d-flex justify-content-between pt-md-3 pt-4 pt-md-2">
                    <div className="d-flex">
                        <div className="bg-property_bed icon">
                            <div className="count vm_bg_primary text-white rounded-circle text-center">{item.bed_rooms}</div>
                        </div>
                        <div className="bg-property_bath ms-4 icon">
                            <div className="count vm_bg_primary text-white rounded-circle text-center">{item.bath_rooms}</div>
                        </div>
                        <div className="bg-property_park ms-4 icon">
                            <div className="count vm_bg_primary text-white rounded-circle text-center">{item.park_spcs}</div>
                        </div>
                    </div>
                    <div>&#10095;</div>
                </div>
            </div>
        </Col>
        <Col md={6} className="ps-md-3 d-flex align-items-start flex-column justify-content-between">
            <p>
            {item.Ad_text.substring(0,340)}{item.Ad_text.length >340 && '...'}
            </p>
            <div class="d-flex flex-wrap">
                <button className="btn vm_bg_ash rounded-0 d-flex align-items-center me-2 mb-1 mb-md-0">
                    <div className="bg-gallery_icon_sm_black me-2 icon"></div>
                    Photo Gallery
                </button>
                <button className="btn vm_bg_ash rounded-0 d-flex align-items-center me-2 mb-1 mb-md-0">
                    <div className="bg-calculator_icon_sm_black me-2 icon"></div>
                    View
                </button>
            </div>
        </Col>
        </Row>
    </Link>
  );
}

export default BrokerageCard;
