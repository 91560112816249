import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col,Image } from "react-bootstrap";
import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
import innerBanner from "./../assets/img/agent_main_banner4.webp"
import buying_strategies from "./../assets/img/buying_strategies.webp"
import commitment from "./../assets/img/commitment.webp"
import communication from "./../assets/img/communication.webp"
const TITLE = process.env.REACT_APP_SITE_NAME+' - Buy'  

const Buy = () => {
    window.scrollTo(0,0);
    return (
        <>
        <Helmet>
            <title>{ TITLE }</title>
        </Helmet>
        <HeaderComponent />
        <div>
            <div>
                <Image src={innerBanner} className="w-100"/>
            </div>
            <Container className="border-top py-5 mb-5">

                <h1 align="center" className="vm_fm_secondary text-uppercase pt-4 vm_font_bold">THINKING OF BUY A HOME</h1>

                <p className="text-center pt-2">
                Buying a Home can be an overwhelming process and emotionally draining. Finding the right home is not always an easy task.</p>
                
                <p className="text-center">
                I advise buyers to schedule a maximum of 7 homes at a time because any more than that will make a buyer's head spin.Most buyers conduct a lot of research online before ever stepping foot in a home. Buyers spend an average of 6 to 8 weeks, according to the National Association of REALTORS, trying to figure out where they want to live.But once the neighborhood is selected, most buyers end up buying a home after 2 or 3 home tours.We can generally spot overpriced listings and advise you accordingly.
                </p>

                <Row className="pt-md-5 pt-3">
                    <Col className="pb-4 pb-md-0" md={4}>
                        <Image src={buying_strategies} className="w-100"/>
                        <h4 className="text-center text-uppercase py-2 bg-dark text-white px-2">Buying Strategies</h4>
                    </Col>
                    <Col className="pb-4 pb-md-0" md={4}>
                        <Image src={commitment} className="w-100"/>
                        <h4 className="text-center text-uppercase py-2 bg-dark text-white px-2">Commitment</h4>
                    </Col>
                    <Col md={4}>
                        <Image src={communication} className="w-100"/>
                        <h4 className="text-center text-uppercase py-2 bg-dark text-white px-2"> RESPONSIVENES</h4>
                    </Col>
                </Row>

                <p className="text-center pt-md-5 pt-4">
                I advise buyers to schedule a maximum of 7 homes at a time because any more than that will make a buyer's head spin.Most buyers conduct a lot of research online before ever stepping foot in a home. Buyers spend an average of 6 to 8 weeks, according to the National Association of REALTORS, trying to figure out where they want to live.But once the neighborhood is selected, most buyers end up buying a home after 2 or 3 home tours.We can generally spot overpriced listings and advise you accordingly.
                </p>
            </Container>
        </div>
      <FooterComponent />
    </>
  );
};

export default Buy;