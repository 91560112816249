import React from "react";
import { Link } from "react-router-dom";
import NoImageFeature from "../assets/img/featured_area.webp";
import NumberFormat from "react-number-format";

function RelatedProperty({ property }) {
  return (
    <Link to={`/estate/details/${property.property_id}/${property.property_type}`}>
    <div key={property.property_id} className="border p-2 mb-3 shadow">

      {(property.url && property.url != '') ?
        <img className="img-responsive w-100 related_property" src={`${property.url != '' ? process.env.REACT_APP_IMG_SOURCE + '/' + property.url : NoImageFeature}` } />
        :  <img src={`${NoImageFeature}` }/>
      }
      
      <div className="bg-white px-3 py-4 h-100">
          <div className="d-flex justify-content-between">
              <h3 className="vm_font_bold"><NumberFormat value={property.Orig_dol} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h3>
          </div>
          <hr className="my-2"/>
          <p className="mb-0">MLS®{property.Ml_num}</p>
          <p className="mb-0">{property.Addr}, {property.Municipality}</p>
          <hr className="my-2"/>
          <div className="d-flex justify-content-between pt-md-3 pt-4 pt-md-2">
              <div className="d-flex">
                  <div className="bg-property_bed icon">
                      <div className="count vm_bg_primary text-white rounded-circle text-center">{property.bed_rooms}</div>
                  </div>
                  <div className="bg-property_bath ms-4 icon">
                      <div className="count vm_bg_primary text-white rounded-circle text-center">{property.bath_rooms}</div>
                  </div>
                  <div className="bg-property_park ms-4 icon">
                      <div className="count vm_bg_primary text-white rounded-circle text-center">{property.park_spcs}</div>
                  </div>
              </div>
              <div>&#10095;</div>
          </div>
      </div>
    </div>
  </Link>   
  );
}

export default RelatedProperty;
