import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, } from "react-bootstrap";

import HeaderComponent from "../componets/Applications/Header/Header";
import FooterComponent from "../componets/Applications/Footer/Footer";
import FeaturedProperties from "../componets/Applications/Layouts/featured_properties";

const TITLE = process.env.REACT_APP_SITE_NAME+' - PropListing'  
const PropListing = () => {
    return (
        <>
        <Helmet>
            <title>{ TITLE }</title>
        </Helmet>
        <HeaderComponent />
        <div>            
            <Container className="border-top">
                <Row>
                    <Col md={12} className="pt-5">
                        <h1 align="center" className="vm_fm_secondary text-uppercase">Property Listing</h1>
                    </Col>
                </Row>
            </Container>
            <FeaturedProperties/>
        </div>
        <FooterComponent />
        </>
  );
};

export default PropListing;