import React from "react"
import { Link } from 'react-router-dom';
import { Nav, Navbar } from "react-bootstrap";

// const Header = () => {
class Mainmenu extends React.Component {
    render() {    
        return (

            <div>
                <Navbar bg="white" variant="light" sticky="top" expand="sm" collapseOnSelect>
                    <Navbar.Toggle className="coloring" />
                        <Navbar.Collapse>
                        <Nav id="navigation">
                            <Link to="/" onclick="hide_nav()">Home</Link>
                            <Link to="/about" onclick="hide_nav()">About Us</Link>
                            <Link to="/search" onclick="hide_nav()">Residential</Link>
                            <Link to="/comsearch" onclick="hide_nav()">Commerical</Link>
                            <Link to="/estate/toronto" onclick="hide_nav()">Map Search</Link>
                            <Link to="/buy" onclick="hide_nav()">Buy</Link>
                            <Link to="/sell" onclick="hide_nav()">Sell</Link>
                            <Link to="/contact-us" onclick="hide_nav()">Contact us</Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>

        )
    }
}

export default Mainmenu;